import * as user from './App/appEpics';
import * as relationship from './Relationship/epics';
import * as entity from './Entity/epics';

const AllEpics = {
    user,
    relationship,
    entity
}

export default AllEpics;