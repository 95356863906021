import * as React from "react";
import { Badge, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, LinearProgress, DialogProps } from "@material-ui/core";

export interface IDialogContainerProps {
    title?: string;
    badge?: string;
    onBackClick?: () => void;
    actions?: IActionButton[];
    isOpened: boolean;
    isQuerying?: boolean;
    maxWidth?: DialogProps["maxWidth"];
    closeLabel?: string;
    fullScreen?: boolean;
}
export interface IActionButton {
    label: string;
    onClick: () => void;
    disabled?: boolean;
}
export default function DialogPanel({ badge, children, title, actions, onBackClick, isOpened, isQuerying, maxWidth = "sm", closeLabel="Cancel", fullScreen}: React.PropsWithChildren<IDialogContainerProps>) {
    const actionList = [...(actions || [])];
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={maxWidth}
            open={isOpened}
            onClose={onBackClick}
            scroll="paper"
            aria-labelledby="scroll-dialog-title">
            <DetailProgress isQuerying={isQuerying} />
            {!!title && <DialogTitle id="scroll-dialog-title">
                {(!badge && !!title) && <span>{title}</span>}
                {(!!badge && !!title) && <Badge badgeContent={badge} color="secondary">
                    <span>{title}</span>
                </Badge>}
            </DialogTitle>}
            <DialogContent dividers={true}>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackClick} color="primary">
                    {closeLabel}
                    </Button>
                {actionList.map((action, idx) => (<Button key={idx} color="primary" onClick={action.onClick} disabled={action.disabled}>{action.label}</Button>))}
            </DialogActions>
        </Dialog>
    );
}
interface IDetailProgressProps {
    isQuerying: boolean | undefined;
}
function DetailProgress({ isQuerying }: IDetailProgressProps) {
    return <LinearProgress hidden={!isQuerying} variant="query" />;
}
