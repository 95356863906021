import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { ActionFactories as RelationshipActionFactories, Action as RelationshipAction } from "./slice";
import { mapToPayload } from "lib/rxJsUtility";
import { IDocumentProcessExecutionTaskPortalModel, IUniverseScopeTypeModel, processExecutionApi, relationshipsApi } from "proxy/apiProxy";
import { from, merge, combineLatest } from "rxjs";
import saveAs from "file-saver";

export const mainOpen: Epic<RelationshipAction>
    = action$ => merge(
        action$.pipe(
            mapToPayload("relationship", "relationshipOpen"),
            map(RelationshipActionFactories.relationshipLoad)),
        action$.pipe(
            mapToPayload("relationship", "relationshipOpen"),
            map(RelationshipActionFactories.relationshipProcessesLoad)),
        combineLatest([
            action$.pipe(mapToPayload("app", "applicationLoaded")),
            action$.pipe(mapToPayload("relationship", "relationshipOpen"))])
            .pipe(
                mergeMap(([{ monitoringMacroScripts }, relationshipId]) => from(monitoringMacroScripts.filter(ms => ([IUniverseScopeTypeModel.Counterparty, IUniverseScopeTypeModel.Investor, IUniverseScopeTypeModel.Role] as (IUniverseScopeTypeModel | undefined)[]).includes(ms.singleScope))).pipe(map(i => RelationshipActionFactories.relationshipMonitoringLoad({ ...i, relationshipId }))))
            ));
export const loadDueDiligence: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessesLoad"),
        mergeMap(relationshipId => relationshipsApi.getProcessesAsync({ relationshipId })),
        map(RelationshipActionFactories.relationshipProcessesLoaded));

export const relationshipLoad: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipLoad"),
        mergeMap(relationshipId => relationshipsApi.getAsync({ relationshipId })),
        map(RelationshipActionFactories.relationshipLoaded));

export const loadDashboard: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipMonitoringLoad"),
        mergeMap(async i => relationshipsApi.getMonitoringResultForTargetAsync({ monitoringId: i.id, relationshipId: i.relationshipId })),
        map(RelationshipActionFactories.relationshipMonitoringLoaded));

export const generateReport: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipReportGenerate"),
        mergeMap(async p => relationshipsApi.generateReportAsync(p).then(response => {
            saveAs(response.blob, response.fileName);
            return p.reportTemplateId;
        })),
        map(RelationshipActionFactories.relationshipReportGenerated));

export const generateDocument: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipDocumentGenerate"),
        mergeMap(async p => relationshipsApi.generateDocumentAsync(p).then(response => {
            saveAs(response.blob, response.fileName);
            return p.documentDefinitionId;
        })),
        map(RelationshipActionFactories.relationshipDocumentGenerated));


export const saveDueDilFile: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessFileSave"),
        mergeMap(i => processExecutionApi.saveProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(i => RelationshipActionFactories.relationshipProcessFileSaved(i)));

export const deleteDueDilFile: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessFileDelete"),
        mergeMap(i => processExecutionApi.deleteProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(RelationshipActionFactories.relationshipProcessFileDeleted));

export const loadDueDilFile: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessFileLoad"),
        mergeMap(parameters => processExecutionApi.getProcessFileAsync(parameters)
            .then(fileResponse => ({ fileResponse, parameters }))),
        tap(({ fileResponse: { blob, fileName } }) => saveAs(blob, fileName)),
        map(i => RelationshipActionFactories.relationshipProcessFileLoaded(i.parameters)));

export const loadQuestionnaire: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessExecutionLoadQuestionnaire"),
        mergeMap(parameters => processExecutionApi.getProcessExistingExecutionQuestionnaireAsync(parameters)
            .then(state => ({ parameters, state }))),
        map(({ parameters: { id: processId, taskCode }, state: taskState }) => RelationshipActionFactories.relationshipProcessExecutionLoadedQuestionnaire({ processId, taskCode, taskState })));

export const saveQuestionnaire: Epic<RelationshipAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessExecutionSaveQuestionnaire"),
        mergeMap(parameters => processExecutionApi.saveProcessExecutionQuestionnaireResponseAsync(parameters)
            .then(task => ({ task, parameters }))),
        map(({ parameters: { id: processId }, task }) => RelationshipActionFactories.relationshipProcessExecutionSavedQuestionnaire({ processId, task })));
