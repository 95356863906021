import { createStyles, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useReduxSelections } from "lib/reduxStoreAccess";

const useStyles = makeStyles(theme => createStyles({
    titleHeader: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: "bold",
        width: "auto",
        color: theme.palette.primary.contrastText
    },
    menuButton: {
        marginRight: theme.spacing(2),
    }
}));

export default function ApplicationTitle() {
    const classes = useStyles();
    const { theming } = useReduxSelections("app");

    return <ListItem className={classes.titleHeader}>
        {!!theming?.image && <img className={classes.menuButton} style={{ height: 40, objectFit: "contain" }} alt="company logo" src={theming.image} />}
        {theming?.currentName && <ListItemText primary={theming.currentName} />}
    </ListItem>
}
