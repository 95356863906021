import produce from "immer";
import {
    RelationshipModel, IGetMyRelationshipsModel,
    IMonitoringResultsetModel,
    IMonitoringMacroScriptSummaryModel,
    IEntitiesGetFileContentParameters,
    IGetProcessExecutionsPortalModel,
    IProcessExecutionGetProcessFileParameters,
    IProcessExecutionDeleteProcessFileParameters,
    IProcessExecutionSaveProcessFileParameters,
    IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters,
    IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "lib/store";
import { IPortalScreenState, IProcessExecutionLoadedQuestionnairePayload, IProcessExecutionSavedQuestionnairePayload, IProcessFileSavedPayload } from "features/AbstractPortal/IPortalScreenState";


export const ActionFactories = produceActionFactories({
    entityOpen: () => undefined,

    entityLoad: () => undefined,
    relationshipLoadedAll: (payload: IGetMyRelationshipsModel) => payload,

    entityMonitoringLoad: (payload: IMonitoringMacroScriptSummaryModel) => payload,
    entityMonitoringLoaded: (payload: IMonitoringResultsetModel) => payload,

    entityReportGenerate: (payload: number) => payload,
    entityReportGenerated: (payload: number) => payload,

    entityDocumentGenerate: (payload: number) => payload,
    entityDocumentGenerated: (payload: number) => payload,

    entityFileContentLoad: (payload: IEntitiesGetFileContentParameters) => payload,
    entityFileContentLoaded: (payload: IEntitiesGetFileContentParameters) => payload,

    entityProcessesLoad: () => undefined,
    entityProcessesLoaded: (payload: IGetProcessExecutionsPortalModel) => payload,

    entityProcessFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => payload,
    entityProcessFileSaved: (payload: IProcessFileSavedPayload) => payload,

    entityProcessFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => payload,
    entityProcessFileDeleted: (payload: IProcessFileSavedPayload) => payload,

    entityProcessFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => payload,
    entityProcessFileLoaded: (payload: IProcessExecutionGetProcessFileParameters) => payload,

    entityProcessExecutionLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => payload,
    entityProcessExecutionLoadedQuestionnaire: (payload: IProcessExecutionLoadedQuestionnairePayload) => payload,

    entityProcessExecutionSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => payload,
    entityProcessExecutionSavedQuestionnaire: (payload: IProcessExecutionSavedQuestionnairePayload) => payload,
});

export interface IState extends IPortalScreenState {
    allLoading: boolean;
    all: RelationshipModel[];
}
export type Action = AnyActionOf<typeof ActionFactories>;
export const reducer = (
    state: IState = {
        allLoading: false,
        all: [],
        dictionaries: {
            entities: {},
            relationships: {},
            portfolios: {},
            securities: {}
        },
        documentIssuing: {},
        reportIssuing: {},
        monitorings: {},
        processesTaskState: {},
        processExecutions: [],
        processExecutionsLoading: false
    },
    action: Action
) => produce(state, draft => {
    const getTaskState = (processId: number, taskCode: string) => {
        draft.processesTaskState[processId] ??= {};
        draft.processesTaskState[processId][taskCode] ??= {};
        return draft.processesTaskState[processId][taskCode];
    }
    switch (action.type) {
        case "entityProcessesLoad":
            draft.processExecutionsLoading = true;
            break;
        case "entityProcessesLoaded":
            draft.processExecutionsLoading = false;
            draft.processExecutions = action.payload.processExecutions;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            break;
        case "entityProcessExecutionLoadQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "entityProcessExecutionLoadedQuestionnaire":
            const taskState = getTaskState(action.payload.processId, action.payload.taskCode);
            taskState.loading = false;
            taskState.questionnaireDetails = action.payload.taskState;
            break;
        case "entityProcessExecutionSaveQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "entityProcessExecutionSavedQuestionnaire":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "entityProcessFileLoad":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "entityProcessFileLoaded":
            getTaskState(action.payload.id, action.payload.taskCode).loading = false;
            break;
        case "entityProcessFileSave":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "entityProcessFileSaved":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "entityProcessFileDelete":
            getTaskState(action.payload.id, action.payload.taskCode).deleting = true;
            break;
        case "entityProcessFileDeleted":
            {
                getTaskState(action.payload.processId, action.payload.task.code).deleting = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "entityMonitoringLoad":
            draft.monitorings[action.payload.id] = { loading: true, ...action.payload };
            break;
        case "entityMonitoringLoaded":
            draft.monitorings[action.payload.monitoringMacroId] = { loading: false, ...action.payload };
            break;
        case "entityLoad":
            draft.allLoading = true;
            // draft.dictionaries = {
            //     entities: {},
            //     relationships: {},
            //     portfolios: {},
            //     processDefinitions: {},
            //     securities: {}
            // };
            break;
        case "relationshipLoadedAll":
            draft.allLoading = false;
            draft.all = action.payload.all;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            break;
        case "entityReportGenerate":
            draft.reportIssuing[action.payload] = true;
            break;
        case "entityReportGenerated":
            draft.reportIssuing[action.payload] = false;
            break;
        case "entityDocumentGenerate":
            draft.documentIssuing[action.payload] = true;
            break;
        case "entityDocumentGenerated":
            draft.documentIssuing[action.payload] = false;
            break;
    }
});

