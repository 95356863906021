import { Box, Divider, makeStyles, SvgIconTypeMap, Tab, TabProps, Tabs, Tooltip, Typography, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import UserDropdown from 'components/UserDropdown';
import { useReduxSelections, useReduxActions } from 'lib/reduxStoreAccess';
import ApplicationTitle from './ApplicationTitle';
import { getUserManager } from 'lib/userManager';
import TenantsDialog from './TenantsDialog';
import { RelationshipModel } from 'proxy/apiProxy';
import { counterpartyTypes } from "components/summaries/FormInterface";
import { IState } from 'features/Entity/slice';
import { getEntityName } from 'lib/modelUtils';
import { useMatches } from 'react-router-dom';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HandshakeOutlineIcon from "mdi-material-ui/HandshakeOutline";
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { IRelationshipSummaryProps, RelationshipScope, useRelationshipSummary } from './RelationshipSummary';

const useStyles = makeStyles(theme => ({
	appBar: {
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		zIndex: theme.zIndex.drawer + 1,
	},
	toolBar: {
		"& > *:not(:first-child)": {
			marginLeft: theme.spacing(1)
		}
	},
	hide: {
		display: 'none',
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	insideToolbarFiller: {
		flexGrow: 1,
	},
	tabButton: {
		minHeight: 64,
	}
}));

function useCurrentRelationship() {
	const matches = useMatches();
	if (!matches.length) {
		return undefined;
	}
	const relationshipId = matches[0].params?.relationshipId;
	if (!relationshipId) {
		return undefined;
	}
	return Number(relationshipId);
}

export default function ApplicationBar() {
	const { currentUser, accessibleTenants = [], currentTenant, tenantsImageUrls } = useReduxSelections("app");
	const { switchTenant, tenantsImageLoad } = useReduxActions("app");
	const tenants = useMemo(() => accessibleTenants.filter(t => t.id !== currentTenant?.id).sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0), [accessibleTenants, currentTenant?.id])
	const handleLogout = useCallback(() => {
		const userManager = getUserManager();
		userManager.signoutRedirect()
	}, []);
	const classes = useStyles();
	const [tenantsOpened, setTenantsOpened] = useState(false);
	const handleOpenChangeTenant = useCallback(() => {
		setTenantsOpened(true);
		tenantsImageLoad();
	}, [tenantsImageLoad]);
	const handleCloseChangeTenant = useCallback(() => setTenantsOpened(false), []);
	const handleTenantSelected = (tenantId: number) => {
		setTenantsOpened(false);
		switchTenant(tenantId);
	}
	const { all, dictionaries } = useReduxSelections("entity");
	return <>
		<AppBar position="fixed" className={classNames(classes.appBar)}>
			<Toolbar className={classes.toolBar}>
				<ApplicationTitle />
				<Box flexGrow={1} />
				{(!!all.length) && <TopMenuTabs
					dictionaries={dictionaries}
					relationships={all} />}
				{currentUser && <UserDropdown user={currentUser} onLogout={handleLogout} onChangeTenant={(accessibleTenants.length > 1) ? handleOpenChangeTenant : undefined} />}
			</Toolbar>
		</AppBar>
		<TenantsDialog accessibleTenants={tenants} opened={tenantsOpened} onCancel={handleCloseChangeTenant} onTenantSelected={handleTenantSelected} tenantsImageUrls={tenantsImageUrls} />
	</>
}

interface ITopMenuTabsProps {
	relationships: RelationshipModel[];
	dictionaries: IState["dictionaries"];
}
function getTabKey(relationshipId: number | undefined) {
	return `r${relationshipId ?? 0}`;
}
function TopMenuTabs({ relationships, dictionaries }: ITopMenuTabsProps) {
	const sortedRelationships = useMemo(() => [...relationships].sort((l, r) => l.type === r.type ? 0 : l.type > r.type ? -1 : 1), [relationships])
	const relationshipId = useCurrentRelationship();
	return <Tabs value={getTabKey(relationshipId)}>
		<EntityTab value={getTabKey(0)} />
		{sortedRelationships.map(relationship => (<RelationshipTab
			value={getTabKey(relationship.id)}
			key={relationship.id}
			relationship={relationship}
			dictionaries={dictionaries} />))}
	</Tabs>
}
function EntityTab(props: SimpleTabProp) {
	return <LinkTab
		label="Home"
		icon={HomeIcon}
		href="/"
		{...props} />
}
type IRelationshipTabProps = SimpleTabProp & {
	relationship: RelationshipModel;
	dictionaries: IState["dictionaries"];
}
function RelationshipTab({ relationship, dictionaries, ...props }: IRelationshipTabProps) {
	const { title, avatar, subTitle } = useMemo(() => getLabel(relationship, dictionaries), [dictionaries, relationship])
	return <LinkTab
		href={`/relationship/${relationship.id}`}
		icon={avatar}
		label={title}
		subLabel={subTitle}
		relationship={relationship}
		{...props} />;
}

function getLabel(relationship: RelationshipModel, dictionaries: IState["dictionaries"]) {
	switch (relationship.type) {
		case "CounterpartyRelationshipModel": return {
			title: "Counterparty",
			subTitle: relationship.title ?? (!!relationship.counterpartyType ? counterpartyTypes[relationship.counterpartyType] : "Counterparty"),
			avatar: AccountBalanceOutlinedIcon
		}
		case "RoleRelationshipModel": return {
			title: "Collaboration/Service",
			subTitle: relationship.title,
			avatar: HandshakeOutlineIcon
		}
		case "InvestorRelationshipModel": return {
			title: "Investor",
			subTitle: (() => {
				if (relationship.fullScope) return "Every portfolio";
				if (!!relationship.sicavIds?.length) return getEntityName(dictionaries.entities[relationship.sicavIds[0] ?? 0]);
				if (!!relationship.portfolioIds?.length) return dictionaries.portfolios[relationship.portfolioIds[0] ?? 0]?.name;
				if (!!relationship.investorIds?.length) return getEntityName(dictionaries.entities[dictionaries.relationships[relationship.investorIds[0] ?? 0]?.entityId ?? 0]);
				return undefined;
			})(),
			avatar: LocalAtmIcon
		}
	}
}

type SimpleTabProp = Omit<TabProps<"a">, "label" | "icon">;


//@ts-ignore
const PanelTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		fontSize: theme.typography.body1,
		boxShadow: theme.shadows[10]
	}
}))(Tooltip);

type ILinkTabProps = SimpleTabProp & {
	label: string;
	subLabel?: string | null;
	icon: OverridableComponent<SvgIconTypeMap>;
	href: string;
	relationship?: RelationshipModel;
}
function LinkTab({ icon, label, subLabel, href, relationship, ...props }: ILinkTabProps) {
	const MenuIcon = icon;
	const classes = useStyles();
	if (relationship) {
		return <Tab component={NavLink} to={href} className={classes.tabButton} value={getTabKey(relationship.id)}
			label={
				<PanelTooltip title={<RelationshipTooltipContent relationship={relationship} />} >
					<Box display="flex" alignItems="center" style={{ gap: 16, paddingLeft: 32, paddingRight: 32 }}>
						<MenuIcon fontSize="large" />
						<Box display="flex" flexDirection="column">
							<Typography variant="h5" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: 180 }}>{label}</Typography>
							{!!subLabel && <Typography variant="overline">{subLabel}</Typography>}
						</Box>
					</Box>
				</PanelTooltip>
			} {...props} />
	}
	else {
		return <Tab component={NavLink} to={href} className={classes.tabButton} value={getTabKey(0)}
			label={
				<Box display="flex" alignItems="center" style={{ gap: 16, paddingLeft: 32, paddingRight: 32 }}>
					<MenuIcon fontSize="large" />
					<Box display="flex" flexDirection="column">
						<Typography variant="h5" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: 180 }}>{label}</Typography>
						{!!subLabel && <Typography variant="overline">{subLabel}</Typography>}
					</Box>
				</Box>
			} {...props} />
	}
}



export function RelationshipTooltipContent({ relationship }: IRelationshipSummaryProps) {
	const { icon, title, subTitle } = useRelationshipSummary(relationship);
	const MenuIcon = icon;
	return <Box padding={1} display="flex" style={{ gap: 16 }} flexDirection="column">
		<Box display="flex" alignItems="center" style={{ gap: 16 }}>
			<MenuIcon fontSize="large" />
			<Box display="flex" flexDirection="column">
				<Typography variant="h4">{title}</Typography>
				<Typography variant="overline">{subTitle}</Typography>
			</Box>
		</Box>
		<Divider />
		<RelationshipScope relationship={relationship} />
	</Box>
}
