import { Epic } from 'redux-observable'
import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { entitiesApi, profileAccountApi, tenantsApi, ICompanySummaryModel } from "proxy/apiProxy";
import { mapToPayload } from "lib/rxJsUtility";
import { referencesApi, parametersApi } from "proxy/apiProxy";
import { ActionFactories, Action } from "./appSlice";
import { base64toBlob } from 'lib/utility';
import { getTenantId, setTenantId } from 'lib/dataAccess';
import { from, merge } from 'rxjs';
import { getEntityName } from 'lib/modelUtils';

export const onSigninCallback: Epic<Action> = action$ => action$.pipe(
    mapToPayload("app", "onSigninCallback"),
    mergeMap(async user => {
        var accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
        if (accessibleTenants.type === "NoTenantModel") {
            return [];
        }
        const tenants = accessibleTenants.tenants;
        let tenantId = getTenantId();
        if (!!tenantId) {
            const tenant = tenants.find(i => i.id === tenantId);
            if (!tenant) {
                setTenantId(undefined);
                tenantId = undefined;
            }
        }
        if (!tenantId) {
            if (tenants.length) {
                tenantId = tenants[0].id;
                setTenantId(tenantId);
            }
            else {
                return [];
            }
        }
        return await loadAppAsync(tenants);
    }),
    map(([reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, documentDefinitions, countries, processClassificationTypes, accessibleTenants, theming]) =>
        ActionFactories.applicationLoaded(
            { reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, documentDefinitions, countries, processClassificationTypes ,accessibleTenants, theming }))
);


async function loadAppAsync(tenants: ICompanySummaryModel[]) {
    const entity = await entitiesApi.getAsync();
    const image = await (async function () {
        if (entity.entity.hasPersonalPortalTheme) {
            try {
                return await entitiesApi.getImageAsync();
            }
            catch { }
        }
        try {
            return await profileAccountApi.getCurrentTenantImageAsync();
        }
        catch { }
        return undefined;
    })();
    const parameters = await parametersApi.getAsync();
    const colorTheme = entity.entity.hasPersonalPortalTheme
        ? entity.entity.themeConfig
        : parameters.themeConfig;

    const currentTenant = await profileAccountApi.getCurrentTenantAsync();

    const imageUrl = !image ? undefined : window.URL.createObjectURL(base64toBlob(image.data, image.mimeType));
    return await Promise.all([
        referencesApi.getAllReportTemplateCategoriesAsync(),
        profileAccountApi.getCurrentAsync(),
        parametersApi.getAsync(),
        Promise.resolve(currentTenant),
        referencesApi.getAllCurrenciesAsync(),
        referencesApi.getAllReportTemplatesAsync(),
        referencesApi.getAllMonitoringMacroScriptsAsync(),
        referencesApi.getAllDocumentDefinitionsAsync(),
        referencesApi.getAllCountriesAsync(),

        // profileAccountApi.getCurrentTenantImageAsync()
        //     .then(({ data, mimeType }) => window.URL.createObjectURL(base64toBlob(data, mimeType)))
        //     .catch(() => undefined),
        referencesApi.getAllProcessClassificationTypesAsync(),
        Promise.resolve(tenants),
        Promise.resolve({ image: imageUrl, themeConfig: colorTheme, currentName: entity.entity.hasPersonalPortalTheme ? getEntityName(entity.entity) : currentTenant.name })
    ]);
}


export const onApplicationLoad: Epic<Action> = action$ => merge(
    action$.pipe(mapToPayload("app", "applicationLoad")),
    action$.pipe(mapToPayload("app", "switchTenant")),
).pipe(
    mergeMap(async tenantId => {
        var accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
        if (accessibleTenants.type === "NoTenantModel") {
            return [];
        }
        const tenants = accessibleTenants.tenants;
        if (!!tenantId) {
            const tenant = tenants.find(i => i.id === tenantId);
            if (!tenant) {
                setTenantId(undefined);
                return [];
            }
        }
        if (!tenantId) {
            setTenantId(undefined);
            return [];
        }

        setTenantId(tenantId);

        return await loadAppAsync(tenants);
    }),
    map(([reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, documentDefinitions, countries, processClassificationTypes, accessibleTenants, theming]) =>
        ActionFactories.applicationLoaded(
            { reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, documentDefinitions, countries, processClassificationTypes, accessibleTenants, theming }))
);



export const tenantImageLoad: Epic<Action> = action$ => action$.pipe(
    mapToPayload("app", "tenantsImageLoad"),
    withLatestFrom(action$.pipe(mapToPayload("app", "applicationLoaded"))),
    mergeMap(([, { accessibleTenants }]) => from(accessibleTenants).pipe(mergeMap(({ id }) => tenantsApi.getImageAsync({ id }).then(({ data, mimeType }) => ({ tenantId: id, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
        .catch(() => ({ tenantId: id }))
    ))),
    map(ActionFactories.tenantImageLoaded)
)
