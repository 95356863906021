import { useMemo } from "react";
import { Card, CardContent, CardHeader, Avatar, makeStyles, Typography, SvgIconTypeMap } from "@material-ui/core";
import { RelationshipModel } from "proxy/apiProxy";
import { counterpartyTypes } from "components/summaries/FormInterface";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HandshakeOutlineIcon from "mdi-material-ui/HandshakeOutline";
import { getEntityName } from "lib/modelUtils";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useReduxSelections } from "lib/reduxStoreAccess";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
    }
}));

export interface IRelationshipSummaryProps {
    relationship: RelationshipModel;
}
export function RelationshipSummary({ relationship }: IRelationshipSummaryProps) {
    const { icon, title, subTitle } = useRelationshipSummary(relationship);
    return <RelationshipSummaryBase title={title} subTitle={subTitle} icon={icon} >
        <RelationshipScope relationship={relationship} />
    </RelationshipSummaryBase>
}
interface IRelationshipSummaryBaseProps {
    title: string;
    subTitle?: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    children: React.ReactNode;
}
function RelationshipSummaryBase({ title, subTitle, icon, children }: IRelationshipSummaryBaseProps) {
    const classes = useStyles();
    const MenuIcon = icon;
    return <Card>
        <CardHeader title={title} subheader={subTitle} avatar={<Avatar className={classes.avatar}><MenuIcon /></Avatar>} />
        <CardContent>
            {children}
        </CardContent>
    </Card>;
}


interface IRelationshipScopeProps {
    relationship: RelationshipModel
}
export function RelationshipScope({ relationship }: IRelationshipScopeProps) {
    const { dictionaries: { entities, portfolios, relationships } } = useReduxSelections("entity");
    return <>
        {relationship.fullScope && <Typography>Every portfolio</Typography>}
        {!!relationship.sicavIds?.length && <><Typography>Sicavs</Typography>
            <ul>
                {relationship.sicavIds.map(sicavId => <li key={sicavId}>{getEntityName(entities[sicavId])}</li>)}
            </ul></>}
        {!!relationship.portfolioIds?.length && <><Typography>Portfolios</Typography>
            <ul>
                {relationship.portfolioIds.map(portfolioId => <li key={portfolioId}>{portfolios[portfolioId]?.name}</li>)}
            </ul></>}
        {!!relationship.investorIds?.length && <><Typography>Investors</Typography>
            <ul>
                {relationship.investorIds.map(investorId => <li key={investorId}>{getEntityName(entities[relationships[investorId]?.entityId ?? 0])}</li>)}
            </ul></>}
    </>
}


export function useRelationshipSummary(relationship: RelationshipModel) {
    return useMemo(() => {
        switch (relationship.type) {
            case "CounterpartyRelationshipModel": return {
                title: "Counterparty",
                subTitle: relationship.title ?? (!!relationship.counterpartyType ? counterpartyTypes[relationship.counterpartyType] : "Counterparty"),
                icon: AccountBalanceOutlinedIcon
            };
            case "RoleRelationshipModel": return {
                title: "Collaboration/Service",
                subTitle: relationship.title,
                icon: HandshakeOutlineIcon
            };
            case "InvestorRelationshipModel": return {
                title: "Investor",
                subTitle: relationship.title,
                icon: LocalAtmIcon
            };
        }
    }, [relationship]);
}
