import { useCallback, useMemo, useState } from "react";
import { Typography, Box, Grid, LinearProgress, Badge, Tooltip, Chip, Avatar, ChipTypeMap, makeStyles, Button, CircularProgress, Divider, Paper } from "@material-ui/core";
import { IProcessTaskStatusModel, IProcessExecutionPortalModel, IDocumentProcessExecutionTaskPortalModel, IQuestionnaireProcessExecutionTaskPortalModel, IProcessExecutionSaveProcessFileParameters, IProcessExecutionDeleteProcessFileParameters, IProcessExecutionGetProcessFileParameters, IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, IClassificationTypeModel, ProcessExecutionTaskPortalModel } from "proxy/apiProxy";
import AttachmentIcon from '@material-ui/icons/Attachment';
import DropZone from "components/DropZone";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FileDocumentEditOutlineIcon from "mdi-material-ui/FileDocumentEditOutline";
import { processTaskStatuses } from "./InnerPortalScreen";
import { SummaryField } from "components/global/SummaryField";
import { ITaskState } from "./IPortalScreenState";
import { fileToBase64 } from "lib/utility";
import getFlatClassificationTypes from "lib/getFlatClassificationTypes";
import DoneIcon from '@material-ui/icons/Done';
import { SectionGridContainer } from "./SectionGridContainer";
import mailboxesBand from "images/mailboxesBand.jpg";
import SurveyRunnerDialog from "components/SurveyRunnerComponent";

const useProcessStyles = makeStyles((theme) => ({
    statusHeading: {
        flexBasis: '20%',
        flexShrink: 0,
    },
    heading: {
        fontWeight: "bolder"
    },
    // secondaryHeading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     color: theme.palette.text.secondary,
    // },
}));
interface IProcessExecutionProps {
    processExecution: IProcessExecutionPortalModel;
    tasksState: Record<string, ITaskState> | undefined;
    processClassificationTypes: IClassificationTypeModel[];
    onFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => void;
    onFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => void;
    onFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => void;
    onLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => void;
    onSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => void;
}

export function ProcessExecution({ processExecution, onFileLoad, onFileDelete, onFileSave, tasksState = {}, onLoadQuestionnaire, onSaveQuestionnaire, processClassificationTypes }: IProcessExecutionProps) {
    const handleFileLoad = useCallback((p: Omit<IProcessExecutionGetProcessFileParameters, "id">) => onFileLoad({ ...p, id: processExecution.id }), [onFileLoad, processExecution.id]);
    const handleFileDelete = useCallback((p: Omit<IProcessExecutionDeleteProcessFileParameters, "id">) => onFileDelete({ ...p, id: processExecution.id }), [onFileDelete, processExecution.id]);
    const handleFileSave = useCallback((p: Omit<IProcessExecutionSaveProcessFileParameters, "id">) => onFileSave({ ...p, id: processExecution.id }), [onFileSave, processExecution.id]);
    const handleLoadQuestionnaire = useCallback((p: Omit<IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, "id">) => onLoadQuestionnaire({ ...p, id: processExecution.id }), [onLoadQuestionnaire, processExecution.id]);
    const handleSaveQuestionnaire = useCallback((p: Omit<IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, "id">) => onSaveQuestionnaire({ ...p, id: processExecution.id }), [onSaveQuestionnaire, processExecution.id]);
    const classifications = useMemo(() => {
        const classificationTypes = getFlatClassificationTypes(processClassificationTypes);
        const classificationLabels: { label: string, value: string }[] = [];
        for (const key in processExecution.classifications) {
            if (Object.prototype.hasOwnProperty.call(processExecution.classifications, key)) {
                const cId = processExecution.classifications[key];
                const ct = classificationTypes[key as unknown as number];
                classificationLabels.push({ label: ct.name.en, value: ct.classifications[cId].name.en })
            }
        }
        return classificationLabels;
    }, [processClassificationTypes, processExecution.classifications]);
    const title = useMemo(() => {
        if (!classifications.length) {
            return "Submit Documents";
        }
        return <Box display="flex">
            {classifications.map(cl => <Box key={cl.label}>{cl.value}</Box>)}
        </Box>
    }, [classifications]);
    return <SectionGridContainer picture={mailboxesBand} title={title} badge={processExecution.tasks.length}>
        <Grid container spacing={10}>
            {processExecution.tasks.map(task => <Grid key={task.code} item xs={6}>
                <Paper style={{ padding: 16, display: "flex", flexDirection: "column", gap: 8 }}>
                    <TaskHeader task={task} />
                    <TaskHeaderDivider taskState={tasksState[task.code]} />
                    {task.type === "DocumentProcessExecutionTaskPortalModel" && <DocumentTaskDetails
                        task={task}
                        onFileDelete={handleFileDelete}
                        onFileLoad={handleFileLoad}
                        onFileSave={handleFileSave} />}
                    {task.type === "QuestionnaireProcessExecutionTaskPortalModel" && <QuestionnaireTaskDetails
                        task={task}
                        taskState={tasksState[task.code]}
                        onLoadQuestionnaire={handleLoadQuestionnaire}
                        onSaveQuestionnaire={handleSaveQuestionnaire} />}
                </Paper>
            </Grid>)}
        </Grid>
    </SectionGridContainer>
}
interface ILinearProcessTaskProps {
    taskState: ITaskState | undefined;
}
function TaskHeaderDivider({ taskState: { saving, loading, deleting } = {} }: ILinearProcessTaskProps) {
    if (saving || loading || deleting) return <LinearProgress variant="query" />;
    return <Divider />
}
interface IDocumentTaskDetailsProps {
    task: IDocumentProcessExecutionTaskPortalModel;
    onFileSave: (payload: Omit<IProcessExecutionSaveProcessFileParameters, "id">) => void;
    onFileDelete: (payload: Omit<IProcessExecutionDeleteProcessFileParameters, "id">) => void;
    onFileLoad: (payload: Omit<IProcessExecutionGetProcessFileParameters, "id">) => void;


    // onFileLoad(taskCode: string): void;
    // onFileDelete(taskCode: string): void;
    // onFileSave(taskCode: string, file: File): void;
}
function DocumentTaskDetails({ task, onFileLoad, onFileDelete, onFileSave }: IDocumentTaskDetailsProps) {
    const handleClick = useCallback(() => onFileLoad({ taskCode: task.code }), [task.code, onFileLoad]);
    const handleDelete = useCallback(() => onFileDelete({ taskCode: task.code }), [task.code, onFileDelete]);
    const handleSave = useCallback(async (file: File) => {
        const openedFile = await fileToBase64(file);
        onFileSave({
            taskCode: task.code, fileModel: {
                data: openedFile.content,
                mimeType: openedFile.mimeType,
                name: openedFile.fileName
            }
        });
    }, [onFileSave, task.code]);
    return <Box>
        <DropZone
            emptyLabel="Drop the document here"
            disabled={[IProcessTaskStatusModel.Done, IProcessTaskStatusModel.InProgress].includes(task.status)}
            fileName={task.fileName}
            onClick={handleClick}
            onFileOpen={handleSave}
            onClear={handleDelete} />
    </Box>;
}

interface ITitleBadgeContentProps {
    task: ProcessExecutionTaskPortalModel;
}
function TitleBadgeContent({ task }: ITitleBadgeContentProps) {
    switch (task.type) {
        case "DocumentProcessExecutionTaskPortalModel":
            if (!!task.fileName) {
                return <Badge color="primary" badgeContent={<DoneIcon fontSize="small" />}>
                    <Typography>{task.name?.en}&nbsp;{task.isMandatory && <ErrorOutlineIcon fontSize="small" />}</Typography>
                </Badge>
            }
            break;
        case "QuestionnaireProcessExecutionTaskPortalModel":
            if (!!task.filledById) {
                return <Badge color="primary" badgeContent={<DoneIcon fontSize="small" />}>
                    <Typography>{task.name?.en}&nbsp;{task.isMandatory && <ErrorOutlineIcon fontSize="small" />}</Typography>
                </Badge>
            }
            break;
    }
    return <Typography>{task.name?.en}&nbsp;{task.isMandatory && <ErrorOutlineIcon fontSize="small" />}</Typography>;
}
function TitleIcon({ task }: ITitleBadgeContentProps) {
    switch (task.type) {
        case "DocumentProcessExecutionTaskPortalModel": return <AttachmentIcon />
        case "QuestionnaireProcessExecutionTaskPortalModel": return <FileDocumentEditOutlineIcon />
    }
}

interface ITaskHeaderProps {
    task: ProcessExecutionTaskPortalModel;
}
function TaskHeader({ task }: ITaskHeaderProps) {
    const classes = useProcessStyles();
    const completeTitle = useMemo(() => `${task.description?.en ?? task.name?.en} ${task.isMandatory ? "(Required)" : null}`, [task.description?.en, task.isMandatory, task.name?.en]);

    return <Box display="flex" flexDirection="row" width="100%" alignItems="center" gridGap={16}>
        <TitleIcon task={task} />
        <Box>
            <Typography className={classes.heading} gutterBottom={true}>
                <Tooltip title={completeTitle}>
                    <TitleBadgeContent task={task} />
                </Tooltip>
            </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box>
            <Typography className={classes.statusHeading}>
                <ProcessStatusBadge size="small" status={task.status} />
            </Typography>
        </Box>
    </Box>
}


interface IQuestionnaireTaskDetailsProps {
    task: IQuestionnaireProcessExecutionTaskPortalModel;
    taskState: ITaskState | undefined;
    onLoadQuestionnaire: (payload: Omit<IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, "id">) => void;
    onSaveQuestionnaire: (payload: Omit<IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, "id">) => void;
}
function QuestionnaireTaskDetails({ task, taskState: { loading, saving, questionnaireDetails } = {}, onLoadQuestionnaire, onSaveQuestionnaire }: IQuestionnaireTaskDetailsProps) {
    const { responses, template } = questionnaireDetails ?? {}
    const [isSurveyOpened, setIsSurveyOpened] = useState<boolean>(false);

    const handleReset = useCallback(() => {
        setIsSurveyOpened(false);
        onLoadQuestionnaire({ getPreviousAnswers: false, taskCode: task.code });
        setIsSurveyOpened(true);
    }, [onLoadQuestionnaire, task.code]);

    const handleOpenQuestionnaire = useCallback(() => {
        onLoadQuestionnaire({ getPreviousAnswers: true, taskCode: task.code });
        setIsSurveyOpened(true);
    }, [onLoadQuestionnaire, task.code]);

    const handleCloseCancel = useCallback(() => {
        setIsSurveyOpened(false);
    }, []);

    const handleComplete = useCallback((values: any) => {
        onSaveQuestionnaire({ taskCode: task.code, responses: values, complete: true });
        setIsSurveyOpened(false);
    }, [onSaveQuestionnaire, task.code]);

    const handleSave = useCallback((values: any) => {
        onSaveQuestionnaire({ taskCode: task.code, responses: values, complete: false });
        console.log("Saved")
    }, [onSaveQuestionnaire, task.code]);

    return <Box>
        {(!!template && !loading) &&
            <SurveyRunnerDialog
                isOpened={isSurveyOpened} template={template} initialValues={responses}
                onCancel={handleCloseCancel} cancelLabel="Close"
                onComplete={handleComplete} completeLabel="Complete"
                onOk={handleSave} okLabel="Save draft"
                onReset={handleReset} resetLabel="Reset"
            />}
        <Box display="flex" justifyContent="space-evenly">
            <Button onClick={handleOpenQuestionnaire} disabled={saving || loading}
                    endIcon={(saving || loading) && <CircularProgress size={16} />}>
                {!task.filledById ? "Answer questionnaire" : "View and edit current answers"}
            </Button>
        </Box>
        {(!!task.error) && <Grid item xs={12}>
            <SummaryField label="Error" value={task.error} error />
        </Grid>}
    </Box>
}







function getProcessStatusColor(status: IProcessTaskStatusModel) {
    switch (status) {
        case IProcessTaskStatusModel.Refused: return "#cc0000";
        case IProcessTaskStatusModel.Open: return "#D85532";
        case IProcessTaskStatusModel.InProgress: return "#E88A12";
        case IProcessTaskStatusModel.Waiting: return "#8CBDFE";
        case IProcessTaskStatusModel.Done: return "#7CC488";
        case IProcessTaskStatusModel.NotApplicable: return;
        case IProcessTaskStatusModel.NotAvailable: return;
    }
}
function ProcessStatusBadge({ status, size }: { status: IProcessTaskStatusModel; } & Pick<ChipTypeMap["props"], "size">) {
    const backgroundColor = getProcessStatusColor(status);
    const statusLabel = processTaskStatuses[status];
    if (!statusLabel) {
        return <div>Problem!!!</div>;
    }
    return <Chip size={size} variant={(!backgroundColor) ? "outlined" : undefined} style={{ backgroundColor }} avatar={<Avatar>{statusLabel[0]}</Avatar>} label={statusLabel} />;
}
