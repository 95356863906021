import { useReduxActions, useReduxSelections } from "lib/reduxStoreAccess"
import { useCallback, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { InnerPortalScreen } from "../AbstractPortal/InnerPortalScreen";
import { useParams } from "react-router";
import { IUniverseScopeTypeModel, RelationshipModel } from "proxy/apiProxy";
export default function Main() {
    const { relationshipId: strRelationshipId } = useParams<{ relationshipId: string }>();
    const relationshipId = useMemo(() => Number(strRelationshipId), [strRelationshipId]);
    const { relationshipOpen, relationshipReportGenerate, relationshipDocumentGenerate,
        relationshipProcessFileSave,
        relationshipProcessFileDelete,
        relationshipProcessFileLoad,
        relationshipProcessExecutionLoadQuestionnaire,
        relationshipProcessExecutionSaveQuestionnaire
    } = useReduxActions("relationship");
    useEffect(() => {
        if (relationshipId)
            relationshipOpen(relationshipId);
    }, [relationshipOpen, relationshipId]);
    const { processClassificationTypes = [] } = useReduxSelections("app");
    const state = useReduxSelections("relationship");
    const { relationship } = state;
    const handleDocumentGenerate = useCallback((payload: number) => relationshipDocumentGenerate({ documentDefinitionId: payload, relationshipId }), [relationshipDocumentGenerate, relationshipId]);
    const handleReportGenerate = useCallback((payload: number) => relationshipReportGenerate({ reportTemplateId: payload, relationshipId }), [relationshipReportGenerate, relationshipId]);

    return <Grid container spacing={3} style={{ overflow: "clip", paddingBottom: 32, paddingTop: 32 }}>
        <InnerPortalScreen
            documentGenerate={handleDocumentGenerate}
            reportGenerate={handleReportGenerate}
            processClassificationTypes={processClassificationTypes}

            onProcessFileSave={relationshipProcessFileSave}
            onProcessFileDelete={relationshipProcessFileDelete}
            onProcessFileLoad={relationshipProcessFileLoad}
            onProcessLoadQuestionnaire={relationshipProcessExecutionLoadQuestionnaire}
            onProcessSaveQuestionnaire={relationshipProcessExecutionSaveQuestionnaire}

            scopeType={getScopeType(relationship?.type)}
            state={state} />
    </Grid>
}


function getScopeType(type: RelationshipModel["type"] | undefined) {
    switch (type) {
        case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
        case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
        case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
        default: return undefined;
    }
}