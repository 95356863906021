import { useReduxSelections } from "lib/reduxStoreAccess";
import { useCallback, useMemo } from "react";
import dashboardPicture from "images/dashboardBand.jpg";
import reportPicture from "images/documentsBand.jpg";
import { Grid } from "@material-ui/core";
import { IDocumentDefinitionSummaryModel, IProcessTaskStatusModel, IFileModel, IProcessExecutionDeleteProcessFileParameters, IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters, IProcessExecutionGetProcessFileParameters, IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters, IProcessExecutionSaveProcessFileParameters, IReportTemplateSummaryModel, IUniverseScopeTypeModel, IClassificationTypeModel } from "proxy/apiProxy";
import { groupElements } from "lib/groupElements";
import { getEnumLabels, toDictionary } from "lib/utility";
import { SectionGridContainer } from "./SectionGridContainer";
import Monitoring from "./Monitoring";
import { ProcessExecution } from "./ProcessExecution";
import { ReportsPanel } from "./ReportsPanel";
import { IPortalScreenState } from "./IPortalScreenState";

export type ReportElement = ({ type: "T"; } & IReportTemplateSummaryModel) | ({ type: "D"; } & IDocumentDefinitionSummaryModel);
export const processTaskStatuses = getEnumLabels(IProcessTaskStatusModel);

export interface ISubmitCustomScreenDataParameters {
    customScreenId: number;
    values?: any;
}

export interface ISaveProcessFilePayload extends IProcessFileReference {
    fileModel?: IFileModel;
}
export interface IProcessFileReference {
    type: string;
    taskCode: string;
}
export interface IInnerPortalScreenProps {
    state: IPortalScreenState;
    documentGenerate(payload: number): void;
    reportGenerate(payload: number): void;
    scopeType: IUniverseScopeTypeModel | undefined;
    processClassificationTypes: IClassificationTypeModel[];

    onProcessFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => void;
    onProcessFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => void;
    onProcessFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => void;
    onProcessLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => void;
    onProcessSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => void;
}

export function InnerPortalScreen({ state, reportGenerate, documentGenerate,
    onProcessFileSave,
    onProcessFileDelete,
    onProcessFileLoad,
    onProcessLoadQuestionnaire,
    onProcessSaveQuestionnaire,
    scopeType,
    processClassificationTypes }: IInnerPortalScreenProps) {

    const { monitorings, reportIssuing, documentIssuing, processExecutions,
        // processExecutionsLoading, 
        processesTaskState } = state;
    const { documentDefinitions = [], reportTemplates = [], reportTemplateCategories = [] } = useReduxSelections("app");
    const monitoringsLoading = useMemo(() => !!Object.values(monitorings).filter(i => i.loading).length, [monitorings]);
    const reports = useMemo(() => [
        ...reportTemplates.filter(i => i.singleScope === scopeType).map(i => ({ type: "T", ...i })),
        ...documentDefinitions.filter(i => i.singleScope === scopeType).map(i => ({ type: "D", ...i }))
    ] as ReportElement[], [documentDefinitions, reportTemplates, scopeType]);
    const reportGroups = useMemo(() => groupElements(reports, v => v.categoryId), [reports]);
    const reportTemplateCategoryDictionary = useMemo(() => toDictionary(reportTemplateCategories, i => i.id), [reportTemplateCategories]);
    const handleReportClick = useCallback((elt: ReportElement) => {
        switch (elt.type) {
            case "D":
                documentGenerate(elt.id);
                break;
            case "T":
                reportGenerate(elt.id);
                break;
        }
    }, [documentGenerate, reportGenerate]);
    return <>
        {!!Object.keys(monitorings).length && <SectionGridContainer picture={dashboardPicture} loading={monitoringsLoading} badge={Object.keys(monitorings).length}>
            <Grid container spacing={3}>
                {Object.values(monitorings).map(monitoring => <Grid key={monitoring.code} item xs={12}>
                    <Monitoring monitoring={monitoring} />
                </Grid>)}
            </Grid>
        </SectionGridContainer>}

        {!!reportGroups.length && <SectionGridContainer picture={reportPicture} title="Documents & Reports" badge={reportGroups.reduce((a, v) => a + v.elements.length, 0)}>
            <ReportsPanel
                documentIssuing={documentIssuing}
                onClick={handleReportClick}
                reportIssuing={reportIssuing}
                reportTemplateCategoryDictionary={reportTemplateCategoryDictionary}
                reports={reportGroups} />
        </SectionGridContainer>}

        {processExecutions.map(process => <ProcessExecution
            key={process.id}
            processExecution={process}
            tasksState={processesTaskState[process.id]}
            onLoadQuestionnaire={onProcessLoadQuestionnaire}
            onSaveQuestionnaire={onProcessSaveQuestionnaire}
            onFileDelete={onProcessFileDelete}
            onFileLoad={onProcessFileLoad}
            onFileSave={onProcessFileSave}
            processClassificationTypes={processClassificationTypes} />)}
    </>;
}
