import produce from "immer";
import { User } from "oidc-client-ts";
// import * as UserDataAccess from "dataAccess/User";
import { IClassificationTypeModel, ICompanyModel, ICompanySummaryModel, IProfileModel } from "proxy/apiProxy"
import { produceActionFactories, AnyActionOf } from "lib/store";
import { ICurrencyModel, ICountryModel, IEssentialParametersModel, IReportTemplateSummaryModel, IMonitoringMacroScriptSummaryModel, IDocumentDefinitionSummaryModel, IReportTemplateCategoryModel } from "proxy/apiProxy";

export interface IStoreGridState {
    sorting: {
        columnName: string;
        direction: 'asc' | 'desc';
    }[];
    expandedRows: (number | string)[] | undefined;
    expandedDetails: (number | string)[] | undefined;
    filters: {
        columnName: string;
        operation?: string;
        value?: string;
    }[];
    grouping: {
        columnName: string;
    }[];
    columnWidths: {
        columnName: string;
        width: number | string;
    }[];
    columnOrder: string[];
    hiddenColumn: string[];
    expandedGroups?: string[];
}
export interface ISetGridStatePayload {
    screenKey: string;
    sectionKey: string;
    state: IStoreGridState;
}

export interface IApplicationLoadedPayload {
    profile: IProfileModel,
    parameters: IEssentialParametersModel,
    currentTenant: ICompanyModel,
    currencies: Record<string | number, ICurrencyModel>,
    reportTemplates: IReportTemplateSummaryModel[],
    reportTemplateCategories: IReportTemplateCategoryModel[],
    monitoringMacroScripts: IMonitoringMacroScriptSummaryModel[],
    documentDefinitions: IDocumentDefinitionSummaryModel[],
    countries: Record<string | number, ICountryModel>,
    accessibleTenants: ICompanySummaryModel[];
    theming: IThemingState;
    processClassificationTypes: IClassificationTypeModel[]
}
export interface IThemingState {
    image?: string
    themeConfig?: string;
    currentName?: string | null;
}
export const ActionFactories = produceActionFactories({
    setGridState: (payload: ISetGridStatePayload) => payload,
    requestLogout: () => undefined,
    onSigninCallback: (payload: User) => payload,
    applicationLoaded: (payload: IApplicationLoadedPayload) => payload,
    searchShortcut: () => undefined,

    switchTenant: (payload: number) => payload,
    applicationLoad: (payload: number) => payload,
    tenantImageLoaded: (payload: ITenantImageLoadedPayload) => payload,
    tenantsImageLoad: () => undefined,
    dummy: () => undefined
});
export interface ITenantImageLoadedPayload {
    tenantId: number;
    imageUrl?: string;
}

export interface IState {
    gridStates: {
        [screenKey: string]: {
            [sectionKey: string]: IStoreGridState;
        }
    },
    currentUser?: IProfileModel;
    currentTenant?: ICompanyModel;
    referenceCurrencies?: Record<number | string, ICurrencyModel>;
    referenceCountries?: Record<number | string, ICountryModel>;
    reportTemplates?: IReportTemplateSummaryModel[];
    reportTemplateCategories?: IReportTemplateCategoryModel[],
    monitoringMacroScripts?: IMonitoringMacroScriptSummaryModel[],
    documentDefinitions?: IDocumentDefinitionSummaryModel[];
    parameters: IEssentialParametersModel;
    searchShortcutGuid: number;
    applicationLoading: boolean;
    accessibleTenants?: ICompanySummaryModel[];
    tenantsImageUrls: Record<number, string>;
    theming?: IThemingState;
    processClassificationTypes?: IClassificationTypeModel[];
}
export type Action = AnyActionOf<typeof ActionFactories>;

export function reducer(
    state: IState = {
        gridStates: {},
        parameters: {},
        searchShortcutGuid: 0,
        applicationLoading: false,
        tenantsImageUrls: {},
    },
    action: Action
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "setGridState":
                if (!draft.gridStates[action.payload.screenKey]) {
                    draft.gridStates[action.payload.screenKey] = {};
                }
                draft.gridStates[action.payload.screenKey][action.payload.sectionKey] = action.payload.state;
                break;
            case "applicationLoaded":
                draft.theming = action.payload.theming;
                draft.referenceCountries = action.payload.countries;
                draft.referenceCurrencies = action.payload.currencies;
                draft.reportTemplates = action.payload.reportTemplates;
                draft.reportTemplateCategories = action.payload.reportTemplateCategories;
                draft.monitoringMacroScripts = action.payload.monitoringMacroScripts;
                draft.parameters = action.payload.parameters;
                draft.documentDefinitions = action.payload.documentDefinitions;
                draft.currentTenant = action.payload.currentTenant;
                draft.currentUser = action.payload.profile;
                draft.applicationLoading = false;
                draft.accessibleTenants = action.payload.accessibleTenants;
                draft.processClassificationTypes = action.payload.processClassificationTypes;
                break;
            case "searchShortcut":
                draft.searchShortcutGuid = draft.searchShortcutGuid + 1;
                break;
            case "onSigninCallback":
                draft.applicationLoading = true;
                break;
            case "tenantImageLoaded":
                if (action.payload.imageUrl) draft.tenantsImageUrls[action.payload.tenantId] = action.payload.imageUrl;
                else delete draft.tenantsImageUrls[action.payload.tenantId];
                break;
        }
    });
}
