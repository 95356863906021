import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { ActionFactories as EntityActionFactories, Action as EntityAction } from "./slice";
import { mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { IDocumentProcessExecutionTaskPortalModel, IUniverseScopeTypeModel, entitiesApi, processExecutionApi, relationshipsApi } from "proxy/apiProxy";
import { from, merge, combineLatest } from "rxjs";
import saveAs from "file-saver";
import { IAnyAction } from "features";

export const applicationLoaded: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("app", "applicationLoaded"),
        map(() => EntityActionFactories.entityLoad())
    );

export const mainOpen: Epic<EntityAction>
    = action$ => merge(
        action$.pipe(
            mapToPayload("entity", "entityOpen"),
            map(EntityActionFactories.entityLoad)),
        action$.pipe(
            mapToPayload("entity", "entityOpen"),
            map(EntityActionFactories.entityProcessesLoad)),
        combineLatest([
            action$.pipe(mapToPayload("app", "applicationLoaded")),
            action$.pipe(mapToPayload("entity", "entityOpen"))])
            .pipe(
                mergeMap(([{ monitoringMacroScripts }]) => from(monitoringMacroScripts.filter(ms => ms.singleScope === IUniverseScopeTypeModel.Entity)).pipe(map(EntityActionFactories.entityMonitoringLoad)))
            ));

export const getFileContent: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityFileContentLoad"),
        mergeMap(payload => entitiesApi.getFileContentAsync(payload)
            .then(file => ({ file, payload }))
            .catch(() => undefined)),
        onlyNotNull(),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ payload }) => EntityActionFactories.entityFileContentLoaded(payload)));

export const loadDueDiligence: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessesLoad"),
        mergeMap(entitiesApi.getProcessesAsync),
        map(EntityActionFactories.entityProcessesLoaded));

export const loadRelationships: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityLoad"),
        mergeMap(() => relationshipsApi.getAllAsync()),
        map(EntityActionFactories.relationshipLoadedAll));

export const loadDashboard: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityMonitoringLoad"),
        mergeMap(monitoring => entitiesApi.getMonitoringResultForTargetAsync({ monitoringId: monitoring.id })),
        map(EntityActionFactories.entityMonitoringLoaded));

export const generateReport: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityReportGenerate"),
        mergeMap(id => entitiesApi.generateReportAsync({ reportTemplateId: id }).then(response => {
            saveAs(response.blob, response.fileName);
            return id;
        })),
        map(EntityActionFactories.entityReportGenerated));

export const generateDocument: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDocumentGenerate"),
        mergeMap(id => entitiesApi.generateDocumentAsync({ documentDefinitionId: id }).then(response => {
            saveAs(response.blob, response.fileName);
            return id;
        })),
        map(EntityActionFactories.entityDocumentGenerated));

export const saveDueDilFile: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessFileSave"),
        mergeMap(i => processExecutionApi.saveProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(i => EntityActionFactories.entityProcessFileSaved(i)));

export const deleteDueDilFile: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessFileDelete"),
        mergeMap(i => processExecutionApi.deleteProcessFileAsync(i).then(t => ({ task: t as IDocumentProcessExecutionTaskPortalModel, processId: i.id }))),
        map(EntityActionFactories.entityProcessFileDeleted));

export const loadDueDilFile: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessFileLoad"),
        mergeMap(parameters => processExecutionApi.getProcessFileAsync(parameters)
            .then(fileResponse => ({ fileResponse, parameters }))),
        tap(({ fileResponse: { blob, fileName } }) => saveAs(blob, fileName)),
        map(i => EntityActionFactories.entityProcessFileLoaded(i.parameters)));

export const loadQuestionnaire: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessExecutionLoadQuestionnaire"),
        mergeMap(parameters => processExecutionApi.getProcessExistingExecutionQuestionnaireAsync(parameters)
            .then(state => ({ parameters, state }))),
        map(({ parameters: { id: processId, taskCode }, state: taskState }) => EntityActionFactories.entityProcessExecutionLoadedQuestionnaire({ processId, taskCode, taskState })));

export const saveQuestionnaire: Epic<EntityAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessExecutionSaveQuestionnaire"),
        mergeMap(parameters => processExecutionApi.saveProcessExecutionQuestionnaireResponseAsync(parameters)
            .then(task => ({ task, parameters }))),
        map(({ parameters: { id: processId }, task }) => EntityActionFactories.entityProcessExecutionSavedQuestionnaire({ processId, task })));
