import * as React from "react";
import {
    IMonitoringHistogramChartModel, IMonitoringLineChartModel, IMonitoringPieChartModel, IMonitoringResultListModel,
    IMonitoringResultProblemModel, IMonitoringResultValueReferenceModel, IMonitoringResultRowModel, IMonitoringResultSectionModel, IMonitoringResultsetModel,
    IMonitoringResultValidityModel, IMonitoringResultValueCheckModel, IMonitoringResultValueDateModel, IMonitoringResultValueDateTypeModel,
    IMonitoringResultValueNumberModel, IMonitoringResultValueNumberTypeModel, IMonitoringResultValueStringModel, IProgressionLevelModel,
    ISentimentModel, isMonitoringResultValueModel, MacroScriptSummaryModel, MonitoringChartModel, MonitoringResultModel,
    MonitoringResultValueModel, IReferenceTypeModel, IMonitoringScatterPlotChartModel, IMonitoringResultValueMarkdownModel, IMonitoringResultValueDueDiligenceFileModel, IDueDiligenceTypeModel, IMonitoringResultValueFileModel, IFileSourceTypeModel
} from "proxy/apiProxy";
import FieldBox from "../FieldBox";
import {
    Card, CardContent, Typography, Avatar, Paper, CardHeader, withStyles, Box, Tabs, Tab, Table, TableHead, TableCell, TableRow, TableBody, LinearProgress, Collapse, ButtonGroup, Button, Tooltip, Grid
} from "@material-ui/core";
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ArrowUpBoldIcon from 'mdi-material-ui/ArrowUpBold';
import ArrowDownBoldIcon from 'mdi-material-ui/ArrowDownBold';
import {
    formatDecimal, formatPrecisePercentage, formatDate, formatDateTime, formatInteger, formatPercentage, formatPreciseDecimal, toDictionary, Unpack
} from "lib/utility";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import { Variant } from "@material-ui/core/styles/createTypography";
import { SummaryField, InnerSummaryField } from "./SummaryField";
import ExtendedGrid, { ColumnType, IColumnDefinition, IGridState } from "../ExtendedGrid";
import { DataType } from 'csstype';
import SimplePieChart from "../SimplePieChart";
import { Datum, LineProps, ResponsiveLine, Serie as LineSerie, SliceTooltip } from "@nivo/line";
import { BarDatum, BarLegendProps, ResponsiveBar } from "@nivo/bar";
import { AxisProps } from "@nivo/axes";
import { CartesianMarkerProps, ValueFormat } from "@nivo/core";
import Link from '@material-ui/core/Link';
// import { IUrlKey } from "tools/lib/UrlDictionary";
// import { useReduxActions } from "lib/reduxStoreAccess";
// import * from ""
// import DirectedGraph, { IGraphProps } from "tools/directedGraphVisu/DirectedGraph";
import * as Uuid from 'uuid';
import FlowGraph, { IFlowGraphProps } from "components/diagram/FlowGraph";
import styled from '@emotion/styled';
import { ResponsiveScatterPlot, ScatterPlotRawSerie, ScatterPlotDatum, ScatterPlotTooltip, ScatterPlotNodeDynamicSizeSpec, ScatterPlotCommonProps, ScatterPlotMouseHandler, ScatterPlotNodeProps } from "@nivo/scatterplot";
import getTimeAxisSpecs from "./getTimeAxisSpecs";
import { makeOpacity } from "lib/findColorBetween";
import ReactHtmlParser from 'react-html-parser';
import showdown from "showdown";
import { ExpandCollapseButton } from "./ExpandCollapseButton";
import 'github-markdown-css/github-markdown-light.css'
import { useReduxActions } from "lib/reduxStoreAccess";
// import FabContainer from "tools/components/FabContainer";
import PivotTableUI, { PivotTableUIProps } from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import FabContainer from "components/FabContainer";
import { TableInput } from "react-pivottable";
const legendSize = 250;
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        smallAvatar: {
            width: 30,
            height: 30,
            backgroundColor: theme.palette.primary.main
        },
        regularAvatar: {
            backgroundColor: theme.palette.primary.main

        },
        detailPaper: {
            height: "100%",
            // overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            width: "100%"
        },
        detailContent: {
            padding: theme.spacing(2)
        },
        detailContentNoPadding: {
            padding: 0
        },
        detailTitle: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(1),
        },
        tabContainer: {
            backgroundColor: theme.palette.primary.light
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    }),
);
interface IMarkDownProps {
    markDown: string;
}
function MarkDown({ markDown }: IMarkDownProps) {
    const converter = new showdown.Converter();
    converter.setFlavor("github")
    const htmlText = converter.makeHtml(markDown);
    return <Box className="markdown-body" bgcolor="initial">
        {ReactHtmlParser(htmlText)}
    </Box>
}

const ValueContainer = withStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&>:first-child": {
                marginRight: theme.spacing(1)
            },
        }
    })
)(Box);

interface IListViewResultComponentProps {
    rows: IMonitoringResultRowModel[];
    hiddenColumns: string[];
}
function ListViewResultComponent({ rows }: IListViewResultComponentProps) {
    const firstRow = rows[0];
    const alignments = getHeadersAlignments(rows);
    const headers = firstRow.values.map((v, idx) => ({
        // renderValue: (row: IMonitoringResultRow) => <ValueContentResultComponent align="right" computationOutputValue={row.values[key]} />,
        name: v.label,
        code: v.code,
        alignment: alignments[idx]
    }));
    return (<div style={{ width: "100%", padding: 0, overflowX: "auto" }}>
        <Table size="small" >
            <TableHead>
                <TableRow>
                    <TableCell />
                    {headers.map((header, idx) => <TableCell key={header.code} align={alignments[idx]}>{header.name}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.code}>
                        <TableCell>
                            <ValueContainer>
                                {(typeof (row.validity) !== "undefined") && <ComputationValidityComponent validity={row.validity} size="small" />}
                                <div>{(!!row.referenceType && !!row.id) ? <ButtonLink textValue={row.label} id={row.id ?? 0} referenceType={row.referenceType} /> : row.label}</div>
                            </ValueContainer>
                        </TableCell>
                        <MonitoringResultListRowValuesComponent row={row} headers={headers} />
                    </TableRow>
                ))}
            </TableBody>
        </Table >
    </div>);
}
function getHeadersAlignments(rows: IMonitoringResultRowModel[]): IColumnDefinition["align"][] {
    const ret = rows[0].values.map(v => null as (IColumnDefinition["align"] | null));
    let nb = ret.length;
    for (const { values } of rows) {
        for (let index = 0; index < values.length; index++) {
            const align = getRowValueAlignment(values[index].type);
            if (!!align && !ret[index]) {
                ret[index] = align;
                if (!--nb) {
                    return ret.map(i => i ?? "right");
                }
            }
        }
    }
    return ret.map(i => i ?? "right");
}
interface IMonitoringResultListRowValuesComponentProps {
    row: IMonitoringResultRowModel;
    headers: { name: string, code: string, alignment: IColumnDefinition["align"] }[];
}
function MonitoringResultListRowValuesComponent({ row: { values }, headers }: IMonitoringResultListRowValuesComponentProps) {
    const valuesDictionary = toDictionary(values, v => v.code);
    return <>{headers.map(({ code, alignment }) => <TableCell key={code} align={alignment ?? "right"}>
        <ListValueResultComponent result={valuesDictionary[code]} />
    </TableCell>)}</>;
}
interface IListValueResultComponentProps {
    result: MonitoringResultValueModel;
}
function ListValueResultComponent({ result }: IListValueResultComponentProps) {
    switch (result.type) {
        case "MonitoringResultValueCheckModel": return <ListValueResultCheckComponent computationOutputValue={result} />;
        case "MonitoringResultValueDateModel": return <ListValueResultDateComponent computationOutputValue={result} />;
        case "MonitoringResultValueNumberModel": return <ListValueResultNumberComponent computationOutputValue={result} />;
        case "MonitoringResultValueStringModel": return <ListValueResultStringComponent computationOutputValue={result} />;
        case "MonitoringResultValueReferenceModel": return <ListValueResultReferenceComponent computationOutputValue={result} />;
        case "MonitoringResultValueDueDiligenceFileModel": return <ListValueResultDueDiligenceFileComponent computationOutputValue={result} />;
        case "MonitoringResultValueFileModel": return <ListValueResultFileComponent computationOutputValue={result} />;
        case "MonitoringResultValueMarkdownModel": return <ListValueResultMarkdownComponent computationOutputValue={result} />;
    }
}
interface IListValueResultNumberComponentProps {
    computationOutputValue: IMonitoringResultValueNumberModel
}
function getNumberTextValue(value: number | undefined, numberType: IMonitoringResultValueNumberTypeModel): string {
    switch (numberType) {
        case IMonitoringResultValueNumberTypeModel.Integer: return formatInteger(value);
        case IMonitoringResultValueNumberTypeModel.Number2: return formatDecimal(value);
        case IMonitoringResultValueNumberTypeModel.Number4: return formatPreciseDecimal(value);
        case IMonitoringResultValueNumberTypeModel.Percentage: return formatPercentage(value);
        case IMonitoringResultValueNumberTypeModel.Percentage2: return formatPrecisePercentage(value);
        case IMonitoringResultValueNumberTypeModel.Percentage4: return formatPrecisePercentage(value, 4);
    }
}
function getDateTimeTextValue(value: Date | undefined, dateType: IMonitoringResultValueDateTypeModel): string {
    switch (dateType) {
        case IMonitoringResultValueDateTypeModel.Date: return formatDate(value);
        case IMonitoringResultValueDateTypeModel.DateTime: return formatDateTime(value);
    }
}
function ListValueResultNumberComponent({ computationOutputValue: { label, value, direction, sentiment, validity, numberType } }: IListValueResultNumberComponentProps) {
    const textValue = getNumberTextValue(value, numberType);

    return <InnerSummaryField
        value={textValue}
        noPadding={true}
        rightAlign={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>}
    />
}
interface IListValueResultCheckComponentProps {
    computationOutputValue: IMonitoringResultValueCheckModel
}
function ListValueResultCheckComponent({ computationOutputValue: { label, value, direction, sentiment, validity } }: IListValueResultCheckComponentProps) {
    return <InnerSummaryField
        value={null}
        color={getSentimentColor(sentiment)}
        noPadding={true}
        endAdornment={<>
            {<CheckComponent checked={value} />}
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>}
    />
}
interface IListValueResultDateComponentProps {
    computationOutputValue: IMonitoringResultValueDateModel
}
function ListValueResultDateComponent({ computationOutputValue: { value, direction, sentiment, validity, dateType } }: IListValueResultDateComponentProps) {
    const textValue = getDateTimeTextValue(value, dateType);

    return <InnerSummaryField
        value={textValue}
        noPadding={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>}
    />
}
interface IListValueResultStringComponentProps {
    computationOutputValue: IMonitoringResultValueStringModel
}
function ListValueResultStringComponent({ computationOutputValue: { value, direction, sentiment, validity } }: IListValueResultStringComponentProps) {
    return <InnerSummaryField
        value={value}
        noPadding={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>} />
}

interface IListValueResultMarkdownComponentProps {
    computationOutputValue: IMonitoringResultValueMarkdownModel;
}
function ListValueResultMarkdownComponent({ computationOutputValue: { value } }: IListValueResultMarkdownComponentProps) {
    return <MarkDown markDown={value} />
}
interface IListValueResultReferenceComponentProps {
    computationOutputValue: IMonitoringResultValueReferenceModel
}
function ListValueResultReferenceComponent({ computationOutputValue: { value, textValue, direction, sentiment, validity, referenceType } }: IListValueResultReferenceComponentProps) {
    return <InnerSummaryField
        value={<ButtonLink id={value} textValue={textValue} referenceType={referenceType} />}
        noPadding={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>} />
}
interface IListValueResultDueDiligenceFileComponentProps {
    computationOutputValue: IMonitoringResultValueDueDiligenceFileModel
}
function ListValueResultDueDiligenceFileComponent({ computationOutputValue: { value, textValue, direction, sentiment, validity, dueDiligenceType, taskCode } }: IListValueResultDueDiligenceFileComponentProps) {
    return <InnerSummaryField
        value={<ButtonDueDiligenceFileLink id={value} textValue={textValue} dueDiligenceType={dueDiligenceType} taskCode={taskCode} />}
        noPadding={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>} />
}
interface IListValueResultFileComponentProps {
    computationOutputValue: IMonitoringResultValueFileModel
}
function ListValueResultFileComponent({ computationOutputValue: { value, textValue, direction, sentiment, validity, sourceType } }: IListValueResultFileComponentProps) {
    return <InnerSummaryField
        value={<ButtonFileLink id={value} textValue={textValue} sourceType={sourceType} />}
        noPadding={true}
        color={getSentimentColor(sentiment)}
        endAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
        </>} />
}
interface IHeaderTitleProps {
    title: string;
    tabs?: ISubMenu[];
    tabValue?: any;
    onTabValueChanged?: (value: any) => void;
    validity?: IMonitoringResultValidityModel
}
function HeaderTitle({ title, tabs, tabValue, validity, onTabValueChanged }: IHeaderTitleProps) {
    const classes = useStyles();
    const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }
    return <Box display="flex" style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4, overflow: "hidden", minHeight: 52 }}>
        <Box className={classes.detailTitle} alignItems="center" display="flex">
            {typeof (validity) !== "undefined" ?? <Avatar style={{ marginRight: 16 }}><ComputationValidityComponent validity={validity} /></Avatar>}
            <Typography variant="h6" color="inherit" noWrap={true}>
                <span style={{ verticalAlign: "middle" }}> {title}</span>
            </Typography>
        </Box>
        <Box className={classes.detailTitle} style={{ flexGrow: 1 }} />
        {tabs && <Tabs
            value={tabValue}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabContainer}
            onChange={handleTabChanged}>
            {tabs.map((tab, idx) => (<Tab key={idx} {...tab} />))}
        </Tabs>}
    </Box>;
}
interface ISubMenu {
    icon?: string | React.ReactElement;
    label: React.ReactNode;
    value: any;
}
interface IDetailContainerProps {
    title: string;
    tabs?: ISubMenu[];
    tabValue?: any;
    onTabValueChanged?: (value: any) => void;
    validity?: IMonitoringResultValidityModel;
}
function DetailTabPanel({ onTabValueChanged, children, title, tabs, tabValue, validity }: React.PropsWithChildren<IDetailContainerProps>) {
    const handleTabChanged = (newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }
    const classes = useStyles();
    return <Paper className={classes.detailPaper}>
        <HeaderTitle
            title={title}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabChanged}
            validity={validity} />
        <Box className={classes.detailContentNoPadding} flexGrow="1" height="100%" position="relative">
            {children}
        </Box>
    </Paper>
}
export interface IMonitoringResultGroupsProps {
    monitoringMacros?: (MacroScriptSummaryModel)[];
    onLoadResult?: (macroScriptId: number) => void;
    resultGroups: Record<number, IMonitoringResultsetModel | null>
}
export default function MonitoringResultGroups({ resultGroups, monitoringMacros, onLoadResult }: IMonitoringResultGroupsProps) {
    if (!monitoringMacros) {
        return <FieldBox display="flex" flexDirection="column">
            {Object.values(resultGroups).map((resultGroup, idx) => <MonitoringResultGroup key={idx} computationMacroResult={resultGroup} />)}
        </FieldBox>
    }
    else {
        return <FieldBox display="flex" flexDirection="column">
            {monitoringMacros.map(monitoringMacro => <MonitoringResultGroup key={monitoringMacro.id} onLoadResult={onLoadResult} monitoringMacro={monitoringMacro} computationMacroResult={resultGroups[monitoringMacro.id]} />)}
        </FieldBox>
    }
}
interface IMonitoringResultGroupProps {
    monitoringMacro?: MacroScriptSummaryModel;
    onLoadResult?: (macroScriptId: number) => void;
    computationMacroResult?: IMonitoringResultsetModel | null;
}
const StyledCardHeader = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
        }
    })
)(CardHeader);
function MonitoringResultGroup({ computationMacroResult, monitoringMacro, onLoadResult }: IMonitoringResultGroupProps) {
    const validity = computationMacroResult?.result?.validity;
    const [expanded, setExpanded] = React.useState(!!computationMacroResult);
    const handleOnExpandChanged = (exp: boolean) => {
        setExpanded(exp);
        if (!onLoadResult || !monitoringMacro) {
            return;
        }
        if (typeof computationMacroResult === "undefined") {
            onLoadResult(monitoringMacro.id);
        }
    }
    const handleRefresh = React.useCallback(() => {
        if (!onLoadResult || !monitoringMacro) {
            return;
        }
        onLoadResult(monitoringMacro.id);
    }, [monitoringMacro, onLoadResult])
    return <Card>
        {computationMacroResult === null && <LinearProgress />}
        <StyledCardHeader
            avatar={validity ? <Avatar><ComputationValidityComponent validity={validity} /></Avatar> : undefined}
            title={monitoringMacro?.name ?? computationMacroResult?.name}
            action={<Box display="flex" gridGap={16}>
                <ExpandCollapseButton expanded={expanded} onChanged={handleOnExpandChanged} /></Box>} />
        <FabContainer onRefreshClick={typeof computationMacroResult !== "undefined" ? handleRefresh : undefined} refreshDisabled={computationMacroResult === null} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                {!computationMacroResult && <Typography>Loading...</Typography>}
                {!!computationMacroResult && <MonitoringResult result={computationMacroResult?.result} depthLevel={1} />}
            </CardContent>
        </Collapse>
    </Card>
}

export interface IMonitoringResultProps {
    result: MonitoringResultModel | undefined;
    depthLevel?: number;
}
export function MonitoringResult({ result, depthLevel = 1 }: IMonitoringResultProps) {
    if (typeof (result) === "undefined") {
        return <NoResultComponent />;
    }
    switch (result.type) {
        case "MonitoringResultProblemModel": return <ExceptionResultComponent computationOutputException={result} />;
        case "MonitoringResultSectionModel": return <SectionResultComponent computationOutputSection={result} depthLevel={depthLevel} />;
        case "MonitoringResultListModel": return <ListResultComponent computationOutputList={result} />;
        case "MonitoringResultValueCheckModel":
        case "MonitoringResultValueDateModel":
        case "MonitoringResultValueNumberModel":
        case "MonitoringResultValueReferenceModel":
        case "MonitoringResultValueDueDiligenceFileModel":
        case "MonitoringResultValueFileModel":
        case "MonitoringResultValueMarkdownModel":
        case "MonitoringResultValueStringModel": return <ValueResultComponent result={result} />;
    }
}
interface IValueResultComponentProps {
    result: MonitoringResultValueModel;
}
function ValueResultComponent({ result }: IValueResultComponentProps) {
    switch (result.type) {
        case "MonitoringResultValueCheckModel": return <ValueResultCheckComponent computationOutputValue={result} />;
        case "MonitoringResultValueDateModel": return <ValueResultDateComponent computationOutputValue={result} />;
        case "MonitoringResultValueNumberModel": return <ValueResultNumberComponent computationOutputValue={result} />;
        case "MonitoringResultValueStringModel": return <ValueResultStringComponent computationOutputValue={result} />;
        case "MonitoringResultValueReferenceModel": return <ValueResultReferenceComponent computationOutputValue={result} />;
        case "MonitoringResultValueDueDiligenceFileModel": return <ValueResultDueDiligenceFileComponent computationOutputValue={result} />;
        case "MonitoringResultValueFileModel": return <ValueResultFileComponent computationOutputValue={result} />;
        case "MonitoringResultValueMarkdownModel": return <ValueResultMarkdownComponent computationOutputValue={result} />;
    }
}
function NoResultComponent() {
    return <Typography variant="h5">
        <WarningIcon style={{ color: "orange" }} /> No result was returned.
    </Typography>;
}
interface IExceptionResultComponentProps {
    computationOutputException: IMonitoringResultProblemModel
}
function ExceptionResultComponent({ computationOutputException: { exception } }: IExceptionResultComponentProps) {
    return <>
        <Typography variant="h5">
            <ErrorOutlineIcon style={{ color: "red" }} /> This macro failed to run.
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
            <pre>{exception}</pre>
        </Typography>
    </>;
}
type IElementGroup = MonitoringResultValueModel[] | IMonitoringResultListModel | IMonitoringResultSectionModel | IMonitoringResultValueMarkdownModel | IMonitoringResultProblemModel;
interface ISectionResultComponentProps {
    computationOutputSection: IMonitoringResultSectionModel;
    depthLevel: number;
}
function* makeElementGroups(elements: MonitoringResultModel[]): Generator<IElementGroup> {
    let valueGroup = [] as MonitoringResultValueModel[];

    for (const element of elements) {
        if (isMonitoringResultValueModel(element) && element.type !== "MonitoringResultValueMarkdownModel") {
            valueGroup.push(element);
        }
        else {
            if (valueGroup.length > 0) {
                yield valueGroup;
                valueGroup = [];
            }
            yield element;
        }
    }
    if (valueGroup.length > 0) {
        yield valueGroup;
    }
}
function generatorToArray<T>(generator: Generator<T>): T[] {
    const ret: T[] = [];
    for (const i of generator) {
        ret.push(i);
    }
    return ret;
}
function SectionResultComponent({ computationOutputSection, depthLevel }: ISectionResultComponentProps) {
    const { elements, label, validity, views } = computationOutputSection;
    const elementGroups = generatorToArray(makeElementGroups(elements));
    const sectionViews = toDictionary(views ?? [], i => i.label);
    const [sectionView, setSectionView] = React.useState((views?.length) ? views[0].label : undefined);
    const currentView = sectionView ? sectionViews[sectionView] : undefined;
    const [expanded, setExpanded] = React.useState(true);

    const tmp = <>
        {views.length > 1 && <ButtonGroup size="small">
            {views.map(view => <Button key={view.label} onClick={setSectionView.bind(null, view.label)} >{view.label}</Button>)}
        </ButtonGroup>}
        {(views.length === 0 || !currentView || currentView.type === "MonitoringInnerSectionsViewModel") && <FieldBox display="flex" flexDirection="column">
            {elementGroups.map((elementGroup, idx) => <PanelsSectionResultComponent key={idx} elementGroup={elementGroup} depthLevel={depthLevel + 1} />)}
        </FieldBox>}
        {(!!currentView && currentView.type === "MonitoringHierarchyViewModel") && <HierarchySectionResultComponent element={computationOutputSection} />}
    </>
    if (depthLevel > 1) {

        return <Box>
            <ComputationTitle title={label} validity={validity} titleVariant="h6" size="normal" expanded={expanded} onExpandChanged={setExpanded} />
            <Collapse in={expanded} timeout="auto">
                {tmp}
            </Collapse>
        </Box>
    }
    else {
        return tmp;
    }
}




interface IHierarchySectionResultComponentProps {
    element: IMonitoringResultSectionModel;
}
function HierarchySectionResultComponent({ element }: IHierarchySectionResultComponentProps) {
    const elementsGroups = React.useMemo(() => {
        const graphProps: IFlowGraphProps = {
            links: [],
            contents: []
        };
        buildSectionDirectedChartData(Uuid.v1(), element, graphProps);
        return graphProps;
    }, [element]);
    return <Box height={500}><FlowGraph {...elementsGroups} /></Box>
}
const SectionNodeGraphContent = styled(Typography)({
    width: 300, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"
});
function buildSectionDirectedChartData(parentId: string | undefined, result: MonitoringResultModel, graphProps: IFlowGraphProps) {
    switch (result.type) {
        // case "MonitoringResultProblemModel":
        //     break;
        case "MonitoringResultSectionModel":
            {
                const id = Uuid.v1();
                graphProps.contents.push({
                    id,
                    content: <SectionNodeGraphContent ><Tooltip title={result.label}><>{result.label}</></Tooltip></SectionNodeGraphContent>
                });
                if (parentId) {
                    graphProps.links.push({
                        fromId: parentId,
                        toId: id
                    });
                }
                for (const elt of result.elements) {
                    buildSectionDirectedChartData(id, elt, graphProps);
                }
            }
            break;
        case "MonitoringResultListModel":
            break;
        case "MonitoringResultValueCheckModel":
        case "MonitoringResultValueDateModel":
        case "MonitoringResultValueNumberModel":
        case "MonitoringResultValueReferenceModel":
        case "MonitoringResultValueMarkdownModel":
        case "MonitoringResultValueStringModel":
            const id = Uuid.v1();
            graphProps.contents.push({
                id,
                content: <ValueResultComponent result={result} />
            });
            if (parentId) {
                graphProps.links.push({
                    fromId: parentId,
                    toId: id
                });
            }
            break;
    }

}

interface IPanelsSectionResultComponentProps {
    elementGroup: IElementGroup;
    depthLevel: number;
}
function PanelsSectionResultComponent({ elementGroup, depthLevel }: IPanelsSectionResultComponentProps) {

    if (Array.isArray(elementGroup)) {
        return <Grid container={true} spacing={2}>
            {elementGroup.map(element => <Grid item={true} xs={3}>
                <MonitoringResult key={element.code} result={element} />
            </Grid>)}
        </Grid>

    }
    return <MonitoringResult key={elementGroup.code} result={elementGroup} depthLevel={depthLevel} />;
}

interface IListResultComponentProps {
    computationOutputList: IMonitoringResultListModel
}
function getRowValueAlignment(type: MonitoringResultValueModel["type"]): IColumnDefinition["align"] {
    switch (type) {
        case "MonitoringResultValueCheckModel": return "center";
        case "MonitoringResultValueDateModel": return "right";
        case "MonitoringResultValueNumberModel": return "right";
        case "MonitoringResultValueStringModel": return "left";
        case "MonitoringResultValueReferenceModel": return "left";
        case "MonitoringResultValueDueDiligenceFileModel": return "left";
        case "MonitoringResultValueFileModel": return "left";
        case "MonitoringResultValueMarkdownModel": return "left";
    }
}
function getDateColumnType(val: IMonitoringResultValueDateModel) {
    switch (val.dateType) {
        case IMonitoringResultValueDateTypeModel.Date: return "date";
        case IMonitoringResultValueDateTypeModel.DateTime: return "dateTime";
    }
}
function getNumberColumnType(val: IMonitoringResultValueNumberModel): ColumnType {
    switch (val.numberType) {
        case IMonitoringResultValueNumberTypeModel.Integer: return "integer";
        case IMonitoringResultValueNumberTypeModel.Number2: return "decimal";
        case IMonitoringResultValueNumberTypeModel.Number4: return "preciseDecimal";
        case IMonitoringResultValueNumberTypeModel.Percentage: return "percentage";
        case IMonitoringResultValueNumberTypeModel.Percentage2: return "precisePercentage2";
        case IMonitoringResultValueNumberTypeModel.Percentage4: return "precisePercentage4";
    }
}
function getColumnType(val: MonitoringResultValueModel): ColumnType {
    switch (val.type) {
        case "MonitoringResultValueCheckModel": return "boolean";
        case "MonitoringResultValueDateModel": return getDateColumnType(val);
        case "MonitoringResultValueNumberModel": return getNumberColumnType(val);
        case "MonitoringResultValueStringModel": return "text";
        case "MonitoringResultValueReferenceModel": return "text";
        case "MonitoringResultValueDueDiligenceFileModel": return "text";
        case "MonitoringResultValueFileModel": return "text";
        case "MonitoringResultValueMarkdownModel": return "text";
    }
}
// function getColumnAdornment({ sentiment, direction, validity }: MonitoringResultValueModel): JSX.Element | null {
//     if (typeof (direction) === "undefined" && typeof (validity) === "undefined") {
//         return null;
//     }
//     return <>
//         {<DirectionComponent direction={direction} sentiment={sentiment} />}
//         {<ComputationValidityComponent validity={validity} />}
//     </>;
// }
function getDateColumnWidth(val: IMonitoringResultValueDateModel) {
    switch (val.dateType) {
        case IMonitoringResultValueDateTypeModel.Date: return 140;
        case IMonitoringResultValueDateTypeModel.DateTime: return 180;
    }
}
function getNumberColumnWidth(val: IMonitoringResultValueNumberModel) {
    switch (val.numberType) {
        case IMonitoringResultValueNumberTypeModel.Integer: return 160;
        case IMonitoringResultValueNumberTypeModel.Number2: return 160;
        case IMonitoringResultValueNumberTypeModel.Number4: return 160;
        case IMonitoringResultValueNumberTypeModel.Percentage: return 140;
        case IMonitoringResultValueNumberTypeModel.Percentage2: return 140;
        case IMonitoringResultValueNumberTypeModel.Percentage4: return 140;
    }
}

function getColumnWidth(val: MonitoringResultValueModel): number {
    switch (val.type) {
        case "MonitoringResultValueCheckModel": return 180;
        case "MonitoringResultValueDateModel": return getDateColumnWidth(val);
        case "MonitoringResultValueNumberModel": return getNumberColumnWidth(val);
        case "MonitoringResultValueStringModel": return 260;
        case "MonitoringResultValueReferenceModel": return 260;
        case "MonitoringResultValueDueDiligenceFileModel": return 260;
        case "MonitoringResultValueFileModel": return 260;
        case "MonitoringResultValueMarkdownModel": return 500;
    }
}
function ListResultComponent({ computationOutputList }: IListResultComponentProps) {
    const charts = toDictionary(computationOutputList.charts ?? [], i => i.label);
    const tabs: ISubMenu[] = [{
        value: "_",
        label: "Data"
    }, {
        value: "___",
        label: "Pivot"
    }];
    if (!computationOutputList.dataOnly) {
        tabs.push({
            value: "__",
            label: "List"
        });
    }
    if (computationOutputList.charts) {
        for (const { label } of computationOutputList.charts) {
            tabs.push({
                value: label,
                label
            });
        }
    }
    const hiddenColumns = computationOutputList.hiddenColumns ?? [];
    const columnsWidth = computationOutputList.columnsWidth ?? {};
    const groupedByColumns = computationOutputList.groupedByColumns ?? [];
    const [chartView, setChartView] = React.useState((computationOutputList.charts?.length)
        ? computationOutputList.charts[0].label
        : computationOutputList.dataOnly ? "_" : "__");
    return <DetailTabPanel
        title={computationOutputList.label}
        validity={computationOutputList.validity}
        tabValue={chartView}
        onTabValueChanged={setChartView}
        tabs={tabs}>
        {!computationOutputList.rows.length && <Typography>No data</Typography>}
        {(!!computationOutputList.rows.length && chartView === "_") && <ListGridResultComponent label={computationOutputList.label} rows={computationOutputList.rows} hiddenColumns={hiddenColumns} columnsWidth={columnsWidth} groups={groupedByColumns} />}
        {(!!computationOutputList.rows.length && chartView === "___") && <ListPivotGridResultComponent label={computationOutputList.label} rows={computationOutputList.rows} hiddenColumns={hiddenColumns} columnsWidth={columnsWidth} groups={groupedByColumns} />}
        {(!!computationOutputList.rows.length && chartView === "__") && <ListViewResultComponent rows={computationOutputList.rows} hiddenColumns={hiddenColumns} />}
        {(!!computationOutputList.rows.length && chartView !== "_" && chartView !== "__" && chartView !== "___") && <ListChartsResultComponent key={chartView} chart={charts[chartView]} rows={computationOutputList.rows} />}
    </DetailTabPanel>
}
interface IListChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    chart: MonitoringChartModel;
}
function InternalListChartsResultComponent({ rows, chart }: IListChartsResultComponentProps) {
    switch (chart.type) {
        case "MonitoringHistogramChartModel": return <ListHistogramChartsResultComponent rows={rows} chart={chart} />
        case "MonitoringLineChartModel": return <ListLineChartsResultComponent rows={rows} chart={chart} />
        case "MonitoringPieChartModel": return <ListPieChartsResultComponent rows={rows} chart={chart} />
        case "MonitoringScatterPlotChartModel": return <ListScatterPlotChartsResultComponent rows={rows} chart={chart} />
    }
}

const ListChartsResultComponent = React.memo(InternalListChartsResultComponent);

function getData(label: string, rows: IMonitoringResultRowModel[], pivotColumnCode: string | undefined, measureColumnCodes: string[], dimensionColumnCode: string)
    : { isDimensionDate: boolean, percentagePrecision: number | null, data: LineSerie[], maxXValue?: number | Date, minXValue?: number | Date, maxYValue?: number | Date, minYValue?: number | Date } {
    // var firstRowValues = toDictionary(rows[0].values, i => i.code.toLowerCase());
    let minXValue: number | Date | undefined = undefined;
    let maxXValue: number | Date | undefined = undefined;
    let minYValue: number | Date | undefined = undefined;
    let maxYValue: number | Date | undefined = undefined;
    const rowsData = rows.map(({ values, ...row }) => ({ ...row, values: toDictionary(values, i => i.code.toLowerCase()) }));
    const firstRowValues = rowsData[0].values;
    const isDimensionDate = firstRowValues[dimensionColumnCode.toLowerCase()]?.type === "MonitoringResultValueDateModel";

    const firstMeasure = firstRowValues[measureColumnCodes[0].toLowerCase()];
    const percentagePrecision = (function () {
        if (firstMeasure?.type !== "MonitoringResultValueNumberModel") {
            return null;
        }
        switch (firstMeasure.numberType) {
            case IMonitoringResultValueNumberTypeModel.Percentage: return 0;
            case IMonitoringResultValueNumberTypeModel.Percentage2: return 2;
            case IMonitoringResultValueNumberTypeModel.Percentage4: return 4;
            default: return null;
        }
    })();

    const data = rowsData.reduce((a, i) => {
        const dimensionValue = i.values[dimensionColumnCode.toLowerCase()];

        for (const measureColumnCode of measureColumnCodes) {
            const measureValue = i.values[measureColumnCode.toLowerCase()];
            if (!dimensionValue || !measureValue) {
                return a;
            }
            if (dimensionValue.type === "MonitoringResultValueCheckModel" || measureValue.type === "MonitoringResultValueCheckModel") {
                return a;
            }
            const xV = dimensionValue.value;
            const yV = measureValue.value;

            if (typeof xV === "undefined") {
                return a;
            }

            const pivotValue = pivotColumnCode ? String(i.values[pivotColumnCode.toLowerCase()].value ?? measureValue.label) : measureValue.label;
            let serie = a[pivotValue.toLowerCase()];
            if (!serie) {
                serie = {
                    id: pivotValue,
                    data: []
                };
                a[pivotValue.toLowerCase()] = serie;
            }

            if (typeof xV !== "string") {
                if (typeof maxXValue === "undefined" || typeof minXValue === "undefined") {
                    maxXValue = xV;
                    minXValue = xV;
                }
                else {
                    if (xV > maxXValue) {
                        maxXValue = xV;
                    }
                    if (xV < minXValue) {
                        minXValue = xV;
                    }
                }
            }

            if (typeof yV !== "string" && typeof yV !== "undefined") {
                if (typeof maxYValue === "undefined" || typeof minYValue === "undefined") {
                    maxYValue = yV;
                    minYValue = yV;
                }
                else {
                    if (yV > maxYValue) {
                        maxYValue = yV;
                    }
                    if (yV < minYValue) {
                        minYValue = yV;
                    }
                }
            }
            serie.data.push({
                x: xV,
                y: yV ?? null
            })
        }


        return a;
    }, {} as Record<string, { id: string, data: Datum[] }>)
    return { isDimensionDate, percentagePrecision, data: Object.values(data), minXValue, maxXValue, minYValue, maxYValue };
}

function getFlatData(rows: IMonitoringResultRowModel[], pivotColumnCode: string | undefined, measureColumnCodes: string[])
    : { percentagePrecision: number | null, data: BarDatum[], keys: string[], numberType: IMonitoringResultValueNumberTypeModel, measures: string[] } {
    var rowsData = rows.map(({ values, ...row }) => ({ ...row, values: toDictionary(values, i => i.code.toLowerCase()) }));

    const firstRowValues = rowsData[0].values;
    const firstMeasure = firstRowValues[measureColumnCodes[0].toLowerCase()];
    const percentagePrecision = (function () {
        if (firstMeasure?.type !== "MonitoringResultValueNumberModel") {
            return null;
        }
        switch (firstMeasure.numberType) {
            case IMonitoringResultValueNumberTypeModel.Percentage: return 0;
            case IMonitoringResultValueNumberTypeModel.Percentage2: return 2;
            case IMonitoringResultValueNumberTypeModel.Percentage4: return 4;
            default: return null;
        }
    })();


    var measures: Record<string, boolean> = {};
    const dataTmp = rowsData.reduce((a, row) => {
        var rowPivotValue = pivotColumnCode ? row.values[pivotColumnCode.toLowerCase()] : undefined;
        var pivotValue = !pivotColumnCode
            ? row.label
            : String(rowPivotValue?.value);
        var node = a[pivotValue];
        node = measureColumnCodes.reduce((n, measureColumnCode) => {
            var measureValue = row.values[measureColumnCode.toLowerCase()];
            measures[String(measureValue.value ?? "_")] = true;
            if (measureValue && measureValue.type === "MonitoringResultValueNumberModel") {
                n[measureValue.label] = (n[measureValue.label] ?? 0) + (measureValue.value ?? 0);
            }
            return n;
        }, (node ?? {}) as Record<string, number>);
        a[pivotValue] = node;
        return a;
    }, {} as Record<string, Record<string, number>>);
    const data = Object.keys(dataTmp).map(key => ({
        key, ...dataTmp[key]
    }))
    const lowerMeasureColumnCodes = measureColumnCodes.map(i => i.toLowerCase());
    const firstMeasureValue = rowsData[0].values[measureColumnCodes[0].toLowerCase()];
    return {
        percentagePrecision,
        data,
        keys: rows[0].values.filter(i => lowerMeasureColumnCodes.includes(i.code.toLowerCase())).map(i => i.label),
        numberType: firstMeasureValue?.type === "MonitoringResultValueNumberModel" ? firstMeasureValue.numberType : IMonitoringResultValueNumberTypeModel.Number2,
        measures: Object.keys(measures)
    }
}



function getScatterData(rows: IMonitoringResultRowModel[], pivotColumnCode: string | undefined, xColumnCode: string, yColumnCode: string, sizeColumnCode?: string)
    : { percentagePrecision: number | null, isDimensionDate: boolean, maxXValue?: number | Date, minXValue?: number | Date, maxYValue?: number, minYValue?: number, maxZValue?: number, minZValue?: number, data: ScatterPlotRawSerie<IScatterPlotNode>[] } {
    var firstRowValues = toDictionary(rows[0].values, i => i.code.toLowerCase());

    var isDimensionDate = firstRowValues[xColumnCode.toLowerCase()]?.type === "MonitoringResultValueDateModel";
    var rowsData = rows.map(({ values, ...row }) => ({ ...row, values: toDictionary(values, i => i.code.toLowerCase()) }));


    const firstMeasure = firstRowValues[yColumnCode.toLowerCase()];
    const percentagePrecision = (function () {
        if (firstMeasure?.type !== "MonitoringResultValueNumberModel") {
            return null;
        }
        switch (firstMeasure.numberType) {
            case IMonitoringResultValueNumberTypeModel.Percentage: return 0;
            case IMonitoringResultValueNumberTypeModel.Percentage2: return 2;
            case IMonitoringResultValueNumberTypeModel.Percentage4: return 4;
            default: return null;
        }
    })();


    // let acceptedValueTypes: MonitoringResultValueModel["type"][] = ["MonitoringResultValueDateModel", "MonitoringResultValueNumberModel"];
    let maxXValue: number | Date | undefined = undefined;
    let minXValue: number | Date | undefined = undefined;
    let maxYValue: number | undefined = undefined;
    let minYValue: number | undefined = undefined;
    let maxZValue: number | undefined = undefined;
    let minZValue: number | undefined = undefined;

    const series = rowsData.reduce((a, v) => {
        const keySerieValue = pivotColumnCode ? v.values[pivotColumnCode.toLowerCase()] : undefined;
        const keySerie = keySerieValue ? String(keySerieValue.value) : "_";
        let serie = a[keySerie.toLowerCase()];
        if (!serie) {
            serie = {
                id: keySerie,
                data: []
            };
            a[keySerie.toLowerCase()] = serie;
        }
        const xValue = v.values[xColumnCode.toLowerCase()];
        const yValue = v.values[yColumnCode.toLowerCase()];
        const zValue = sizeColumnCode ? v.values[sizeColumnCode.toLowerCase()] : undefined;


        if ((xValue.type === "MonitoringResultValueNumberModel" || xValue.type === "MonitoringResultValueDateModel" || xValue.type === "MonitoringResultValueStringModel" || xValue.type === "MonitoringResultValueReferenceModel")
            && (yValue.type === "MonitoringResultValueNumberModel" || yValue.type === "MonitoringResultValueStringModel" || yValue.type === "MonitoringResultValueReferenceModel") && typeof yValue.value !== "undefined"
            && (!zValue || zValue.type === "MonitoringResultValueNumberModel")) {
            const [xV, yV, zV] = [xValue.value, yValue.value, zValue?.value];
            if (typeof xV !== "undefined" && typeof yV !== "undefined") {
                if ((xValue.type === "MonitoringResultValueNumberModel" || xValue.type === "MonitoringResultValueDateModel") && typeof xValue.value !== "undefined") {
                    if (typeof maxXValue === "undefined" || typeof minXValue === "undefined") {
                        maxXValue = xValue.value;
                        minXValue = xValue.value;
                    }
                    else {
                        if (xValue.value > maxXValue) {
                            maxXValue = xValue.value;
                        }
                        if (xValue.value < minXValue) {
                            minXValue = xValue.value;
                        }
                    }
                }
                if (yValue.type === "MonitoringResultValueNumberModel" && typeof yValue.value !== "undefined") {
                    if (typeof maxYValue === "undefined" || typeof minYValue === "undefined") {
                        maxYValue = yValue.value;
                        minYValue = yValue.value;
                    }
                    else {
                        if (yValue.value > maxYValue) {
                            maxYValue = yValue.value;
                        }
                        if (yValue.value < minYValue) {
                            minYValue = yValue.value;
                        }
                    }
                }
                if (typeof maxZValue === "undefined" || typeof minZValue === "undefined") {
                    maxZValue = zV;
                }
                else if (typeof zV !== "undefined") {
                    if (zV > maxZValue) {
                        maxZValue = zV;
                    }
                    if (zV < minZValue) {
                        minZValue = zV;
                    }
                }
                const dataValue: IScatterPlotNode = { x: xV, y: yV, z: zV, row: v };
                serie.data.push(dataValue);
            }
        }
        return a;
    }, {} as { [key: string]: ScatterPlotRawSerie<IScatterPlotNode> })
    return {
        percentagePrecision,
        isDimensionDate,
        maxXValue,
        minXValue,
        maxYValue,
        minYValue,
        maxZValue,
        minZValue,
        data: Object.values(series)
    };
}

function getCustomScatterTooltip({ pivotColumnCode, xColumnCode, yColumnCode, sizeColumnCode }: IMonitoringScatterPlotChartModel) {
    const valuesToShow = [pivotColumnCode, xColumnCode, yColumnCode, sizeColumnCode].filter(i => !!i).reduce((a, v) => {
        if (!!v) {
            a[v] = true;
        }
        return a;
    }, {} as Record<string, boolean>);

    const myCustomScatterTooltip: ScatterPlotTooltip<IScatterPlotNode> = ({ node: { data: { row } } }) => {
        return <div style={{ background: "white", padding: "9px 12px", border: "1px solid #ccc", zIndex: 9999999 }}>
            <strong>{row.label}</strong>
            <Grid container={true} spacing={2} style={{ width: 300 }}>
                {Object.values(row.values).filter(i => !!valuesToShow[i.code]).map(value => <Grid key={value.code} item={true} xs={6}><ValueResultComponent result={value} /></Grid>)}
            </Grid>
        </div>
    }
    return myCustomScatterTooltip;
}
interface IListScatterPlotChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    chart: IMonitoringScatterPlotChartModel;
}
interface IListRow extends Omit<IMonitoringResultRowModel, "values"> {
    values: Record<string, Unpack<IMonitoringResultRowModel["values"]>>
}
interface IScatterPlotNode extends ScatterPlotDatum {
    z?: number;
    row: IListRow;
}

function CustomScatterNode({ node: { x, y, size, color, data: { row } } }: ScatterPlotNodeProps<IScatterPlotNode>) {
    const withCursor = !!row.referenceType && !!row.id;
    return <circle cx={x} cy={y} r={size / 2} fill={makeOpacity(color, 40)} style={{ mixBlendMode: "normal", strokeWidth: 1, stroke: color, cursor: withCursor ? "pointer" : undefined }}></circle>
}

function ListScatterPlotChartsResultComponent({ rows, chart }: IListScatterPlotChartsResultComponentProps) {
    const { pivotColumnCode, xColumnCode, yColumnCode, sizeColumnCode, maxSize } = chart;
    // const { navigationNavigate } = useReduxActions("navigation");
    var { isDimensionDate, data, maxZValue, minXValue, maxXValue, maxYValue, minYValue, percentagePrecision } = React.useMemo(
        () => getScatterData(rows, pivotColumnCode, xColumnCode, yColumnCode, sizeColumnCode),
        [rows, pivotColumnCode, xColumnCode, yColumnCode, sizeColumnCode]);
    const MyCustomScatterTooltip = getCustomScatterTooltip(chart);
    const yFormat = percentagePrecision === null ? undefined : ` >-.${percentagePrecision}%`;
    var sizeSpec: ScatterPlotNodeDynamicSizeSpec | number = sizeColumnCode ? {
        key: "data.z",
        values: [0, maxSize ?? maxZValue ?? 1],
        sizes: [9, 40]
    } : 9;

    const markers: CartesianMarkerProps[] = [];
    if (typeof maxXValue === "number" && typeof minXValue === "number" && maxXValue > 0 && minXValue < 0) {
        markers.push({
            axis: 'x',
            value: 0,
            lineStyle: { stroke: '#b0413e', strokeWidth: 1 }
        });
    }
    if (typeof maxYValue === "number" && typeof minYValue === "number" && maxYValue > 0 && minYValue < 0) {
        markers.push({
            axis: 'y',
            value: 0,
            lineStyle: { stroke: '#b0413e', strokeWidth: 1 }
        });
    }
    const legends: ScatterPlotCommonProps<IScatterPlotNode>["legends"] | undefined = (data.length > 1) ? [
        {
            anchor: "bottom-right",
            direction: "column",
            itemHeight: 20,
            itemWidth: 80,
            translateX: 100
        }
    ] : undefined;
    const handleClick: ScatterPlotMouseHandler<IScatterPlotNode> = ({ data: { row } }) => {
        if (row.referenceType && row.id) {
            // navigationNavigate({ screenKey: getScreenKey(row.referenceType), sectionKey: "detail", parameters: { id: row.id } });
        }
    }
    if (isDimensionDate) {
        const timeAxis = (typeof maxXValue !== "undefined" && typeof minXValue !== "undefined") ? getTimeAxisSpecs(minXValue as Date, maxXValue as Date) : { format: "%d %m %Y", tickValues: "every 1 day" };
        return <div style={{ height: 500, width: "100%" }}>
            <ResponsiveScatterPlot<IScatterPlotNode>
                margin={{ top: 40, right: legends ? legendSize : 40, bottom: 60, left: 80 }}
                data={data}
                tooltip={MyCustomScatterTooltip}
                markers={markers}
                xScale={{
                    type: "time",
                    format: "%Y-%m-%d",
                    precision: "day",
                    min: "auto"
                }}
                yScale={{
                    type: "linear",
                    min: "auto"
                }}


                axisLeft={!!yFormat ? {
                    format: yFormat
                } : undefined}
                yFormat={yFormat}


                nodeSize={sizeSpec}
                xFormat="time:%d %B %Y"
                axisBottom={timeAxis}
                onClick={handleClick}
                nodeComponent={CustomScatterNode}
                legends={legends} />
        </div>
    }
    else {
        return <div style={{ height: 400, width: "100%" }}>
            <ResponsiveScatterPlot<IScatterPlotNode>
                margin={{ top: 40, right: legends ? legendSize : 40, bottom: 60, left: 80 }}
                yScale={{
                    type: "linear",
                    min: "auto"
                }}
                xScale={{
                    type: "linear",
                    min: "auto"
                }}


                axisLeft={!!yFormat ? {
                    format: yFormat
                } : undefined}
                yFormat={yFormat}



                markers={markers}
                data={data}
                onClick={handleClick}
                nodeSize={sizeSpec}
                nodeComponent={CustomScatterNode}
                tooltip={MyCustomScatterTooltip}
                legends={legends} />
        </div>
    }
}


interface IListHistogramChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    chart: IMonitoringHistogramChartModel;
}
function ListHistogramChartsResultComponent({ rows, chart: { stacked, pivotColumnCode, measureColumnCodes } }: IListHistogramChartsResultComponentProps) {
    var { data, keys, numberType, measures } = React.useMemo(
        () => getFlatData(rows, pivotColumnCode, measureColumnCodes),
        [measureColumnCodes, pivotColumnCode, rows]);
    const valueFormat: ValueFormat<number> = (v) => getNumberTextValue(v, numberType);


    const legends: BarLegendProps[] | undefined = (measureColumnCodes.length > 1) ? [
        {
            anchor: "bottom-right",
            dataFrom: "keys",
            direction: "column",
            itemHeight: 20,
            itemWidth: 80,
            translateX: 100
        }
    ] : undefined;

    // const timeAxis = data (typeof maxXValue !== "undefined" && typeof minXValue !== "undefined") ? getTimeAxisSpecs(minXValue as Date, maxXValue as Date) : { format: "%d %m %Y", tickValues: "every 1 day" };
    const axisLeft: AxisProps = { format: valueFormat };

    return <div style={{ height: 400, width: "100%" }}>
        <ResponsiveBar
            data={data}
            margin={{ top: 20, right: (!!legends || measures.length > 10) ? legendSize : 40, bottom: measures.length > 10 ? 120 : 60, left: 80 }}
            keys={keys}
            indexBy="key"
            axisLeft={axisLeft}
            axisBottom={measures.length > 10 ? { tickRotation: 45 } : undefined}
            valueFormat={valueFormat}
            groupMode={stacked ? "stacked" : "grouped"}
            legends={legends}
        />
    </div>
}
interface IListLineChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    chart: IMonitoringLineChartModel;
}
const CustomSliceTooltip: SliceTooltip = ({ slice }) => <div style={{ background: "white", padding: "9px 12px", border: "1px solid #ccc" }}>
    <strong>{slice.points[0]?.data?.xFormatted}</strong>
    {slice.points.map((point) => (
        <div key={point.id} style={{ padding: "3px 0", display: "flex", alignContent: "center" }}>
            <div style={{ backgroundColor: point.serieColor, width: 16, height: 16, borderRadius: 8 }} />
            <div style={{ paddingLeft: 6 }}>{point.serieId}</div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ paddingLeft: 6, textAlign: "right" }}>
                {point.data.yFormatted}
            </div>
        </div>
    ))}
</div>
function ListLineChartsResultComponent({ rows, chart: { label, pivotColumnCode, measureColumnCodes, dimensionColumnCode, stacked } }: IListLineChartsResultComponentProps) {
    var { isDimensionDate, percentagePrecision, data, maxXValue, minXValue, maxYValue, minYValue } = React.useMemo(
        () => getData(label, rows, pivotColumnCode, measureColumnCodes, dimensionColumnCode),
        [label, measureColumnCodes, dimensionColumnCode, pivotColumnCode, rows]);
    const markers: CartesianMarkerProps[] = [];
    if (typeof maxXValue === "number" && typeof minXValue === "number" && maxXValue > 0 && minXValue < 0) {
        markers.push({
            axis: 'x',
            value: 0,
            lineStyle: { stroke: '#b0413e', strokeWidth: 1 }
        });
    }
    if (typeof maxYValue === "number" && typeof minYValue === "number" && maxYValue > 0 && minYValue < 0) {
        markers.push({
            axis: 'y',
            value: 0,
            lineStyle: { stroke: '#b0413e', strokeWidth: 1 }
        });
    }
    const legends: LineProps["legends"] = (data.length > 1) ? [
        {
            anchor: "bottom-right",
            direction: "column",
            itemHeight: 20,
            itemWidth: 80,
            translateX: 100
        }
    ] : undefined;
    const yFormat = percentagePrecision === null ? undefined : ` >-.${percentagePrecision}%`;
    if (isDimensionDate) {
        const timeAxis = (typeof maxXValue !== "undefined" && typeof minXValue !== "undefined") ? getTimeAxisSpecs(minXValue as Date, maxXValue as Date) : { format: "%d %m %Y", tickValues: "every 1 day" };
        return <div style={{ height: 400, width: "100%" }}>
            <ResponsiveLine
                margin={{ top: 20, right: legends ? legendSize : 40, bottom: 60, left: 80 }}
                pointSize={3}
                enableSlices="x"
                markers={markers}
                data={data}
                sliceTooltip={CustomSliceTooltip}
                xScale={{
                    type: "time",
                    format: "%Y-%m-%d",
                    precision: "day"
                }}
                enableArea={stacked}
                yScale={{
                    type: "linear",
                    stacked,
                    min: stacked ? 0 : "auto",
                    max: "auto",
                }}
                axisLeft={!!yFormat ? {
                    format: yFormat
                } : undefined}
                yFormat={yFormat}
                xFormat="time:%d %B %Y"
                axisBottom={timeAxis}
                legends={legends} />
        </div>
    }
    else {
        return <div style={{ height: 400, width: "100%" }}>
            <ResponsiveLine
                margin={{ top: 20, right: legends ? legendSize : 40, bottom: 60, left: 80 }}
                pointSize={3}
                enableSlices="x"
                enableArea={stacked}
                markers={markers}
                yScale={{
                    type: "linear",
                    stacked,
                    min: stacked ? 0 : "auto",
                    max: "auto",
                }}
                data={data}
                axisLeft={!!yFormat ? {
                    format: yFormat
                } : undefined}
                yFormat={yFormat}
                sliceTooltip={CustomSliceTooltip}
                legends={legends} />
        </div>
    }
}
interface IListPieChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    chart: IMonitoringPieChartModel;
}
function ListPieChartsResultComponent({ rows, chart: { pivotColumnCode, measureColumnCodes } }: IListPieChartsResultComponentProps) {
    /* <div style={{ height: 400, width: "100%" }}></div> */
    return <Box display="flex" justifyContent="center" flexWrap="wrap">
        {measureColumnCodes.map(measureColumnCode => <Box key={measureColumnCode} style={{ marginLeft: 8, marginRight: 8, overflow: "visible" }} >
            <ListSimplePieChartsResultComponent rows={rows} pivotColumnCode={pivotColumnCode} measureColumnCode={measureColumnCode} />
        </Box>)}
    </Box>
}
interface IListSimplePieChartsResultComponentProps {
    rows: IMonitoringResultRowModel[];
    pivotColumnCode: string;
    measureColumnCode: string;
}
function ListSimplePieChartsResultComponent({ rows, pivotColumnCode, measureColumnCode }: IListSimplePieChartsResultComponentProps) {
    const label = (measureColumnCode && (rows?.length ?? 0) > 0) ? rows[0].values.find(i => i.code === measureColumnCode)?.label : undefined;
    const value = rows[0].values.find(i => i.code === measureColumnCode);
    const getSliceLabel = (v: number) => getNumberTextValue(v, value?.type === "MonitoringResultValueNumberModel" ? value.numberType : IMonitoringResultValueNumberTypeModel.Number2);
    const handleGetArgument = (row: IMonitoringResultRowModel) => {
        if (!pivotColumnCode) {
            return row.label;
        }
        else {
            const pivotValue = row.values.find(({ code }) => code === pivotColumnCode)?.value;
            if (typeof (pivotValue) !== "undefined") {
                return String(pivotValue);
            }
        }
        return "";
    }
    const handleGetValue = ({ values }: IMonitoringResultRowModel) => {
        const value = values.find(({ code }) => code === measureColumnCode)?.value;
        if (typeof (value) === "number") {
            return value;
        }
        else if (typeof (value) !== "undefined") {
            return 1;
        }
        return 0;
    }
    return <div style={{ width: 600, height: 350, display: "flex", flexDirection: "column", alignItems: "center", margin: 8 }}>
        {(!!label) && <Typography variant="subtitle2">{label}</Typography>}
        <SimplePieChart rows={rows} getArgument={handleGetArgument} getValue={handleGetValue} getSliceLabel={getSliceLabel} />
    </div>
}
interface IListGridResultComponentProps {
    label: string;
    rows: IMonitoringResultRowModel[];
    hiddenColumns: string[];
    groups: string[];
    columnsWidth: Record<string, number>;
}
function InnerListGridResultComponent({ label, rows, hiddenColumns, groups, columnsWidth }: IListGridResultComponentProps) {
    const { columns, gridState } = React.useMemo(() => {
        if (!rows.length) {
            return { columns: [], gridState: {} };
        }
        const columns: IColumnDefinition[] = [{
            name: "Label",
            title: "Label",
            getCellValue: (row: IMonitoringResultRowModel) => (!!row.referenceType && !!row.id) ? <ButtonLink textValue={row.label} id={row.id ?? 0} referenceType={row.referenceType} /> : row.label,
            positionToKeep: "left",
            filteringEnabled: true
        },
        ...rows[0].values.map((v, colIdx) => ({
            name: v.code,
            title: v.label,
            getCellValue: (row: IMonitoringResultRowModel) => {
                const elt = row.values[colIdx];
                if (!elt) return null;
                switch (elt.type) {
                    case "MonitoringResultValueDueDiligenceFileModel": return <ButtonDueDiligenceFileLink id={elt.value} textValue={elt.textValue} dueDiligenceType={elt.dueDiligenceType} taskCode={elt.taskCode} />;
                    case "MonitoringResultValueFileModel": return <ButtonFileLink id={elt.value} textValue={elt.textValue} sourceType={elt.sourceType} />;
                    default: return elt.value;
                }
            },
            foregroundColor: (row: IMonitoringResultRowModel) => getSentimentColor(row.values[colIdx].sentiment),
            align: getRowValueAlignment(v.type),
            filteringEnabled: v.type === "MonitoringResultValueStringModel" || v.type === "MonitoringResultValueDateModel" || v.type === "MonitoringResultValueNumberModel" || v.type === "MonitoringResultValueMarkdownModel",
            columnType: getColumnType(v),
            aggregationType: ["currency", "decimal", "integer", "percentage", "preciseDecimal", "precisePercentage2", "precisePercentage4"].includes(getColumnType(v)) ? "sum" : undefined
        } as IColumnDefinition))
        ];
        const hiddenDico = toDictionary(hiddenColumns, i => i.toLowerCase(), i => true as (true | undefined));
        const groupDico = toDictionary(groups.map((c, idx) => ({ c, idx })), i => i.c.toLowerCase(), i => typeof i.idx === "undefined" ? undefined : (Number(i.idx) + 1));
        const gridState: IGridState = rows[0].values.reduce((a, v) => ({
            ...a, [v.code]: {
                width: columnsWidth[v.code] ?? getColumnWidth(v),
                hidden: hiddenDico[v.code.toLowerCase()],
                groupingPosition: groupDico[v.code.toLowerCase()]
            }
        }), { "Label": { width: 320 } } as IGridState);

        return { columns, gridState };
    }, [columnsWidth, groups, hiddenColumns, rows]);
    const getRowKey = ({ code }: IMonitoringResultRowModel) => code;
    return <div style={{ width: "100%", height: 750 }}>
        <ExtendedGrid
            rows={rows}
            columns={columns}
            getRowId={getRowKey}
            initialState={gridState}
            defaultExportFileName={`${label}.xlsx`}
            userCanGroup={true}
            stateLess={true} />
    </div>
}
const ListGridResultComponent = React.memo(InnerListGridResultComponent);

function InnerListPivotGridResultComponent({ label, rows, hiddenColumns, groups, columnsWidth }: IListGridResultComponentProps) {
    const dataCallback = React.useMemo(() => {
        const columns = [
            { name: "Label", title: "Label", getCellValue: ({ label }: IMonitoringResultRowModel) => label },
            ...rows[0].values.map((v, colIdx) => ({
                name: v.code,
                title: v.label,
                getCellValue: (row: IMonitoringResultRowModel) => row.values[colIdx]?.value,
            }))
        ];
        var data: TableInput = [columns.map(i => i.title ?? i.name ?? ""), ...rows.map(row => columns.map(col => col.getCellValue(row) as string))];
        return data;
    }, [rows]);
    const [state, setState] = React.useState<Omit<PivotTableUIProps, "data" | "onChange">>({});
    return <div style={{ width: "100%", padding: 16 }}>
        <PivotTableUI {...state} data={dataCallback} onChange={setState} />
    </div>
}
const ListPivotGridResultComponent = React.memo(InnerListPivotGridResultComponent);
interface ICheckComponentProps {
    checked?: boolean
}
function CheckComponent({ checked }: ICheckComponentProps) {
    if (typeof (checked) === "undefined") {
        return null;
    }
    if (checked) {
        return <CheckBoxOutlinedIcon />;
    }
    return <CheckBoxOutlineBlankOutlinedIcon />;
}
interface IValueResultNumberComponentProps {
    computationOutputValue: IMonitoringResultValueNumberModel
}
function ValueResultNumberComponent({ computationOutputValue: { label, value, direction, sentiment, validity, numberType } }: IValueResultNumberComponentProps) {
    const textValue = getNumberTextValue(value, numberType);

    return <SummaryField
        label={label}
        value={textValue}
        rightAlign={true}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />} />
}
interface IValueResultCheckComponentProps {
    computationOutputValue: IMonitoringResultValueCheckModel
}
function ValueResultCheckComponent({ computationOutputValue: { label, value, direction, sentiment, validity } }: IValueResultCheckComponentProps) {
    return <SummaryField
        label={null}
        value={label}
        color={getSentimentColor(sentiment)}
        startAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {<CheckComponent checked={value} />}
        </>
        }
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
interface IValueResultDateComponentProps {
    computationOutputValue: IMonitoringResultValueDateModel
}
function ValueResultDateComponent({ computationOutputValue: { label, value, direction, sentiment, validity, dateType } }: IValueResultDateComponentProps) {
    const textValue = getDateTimeTextValue(value, dateType);

    return <SummaryField
        label={label}
        value={textValue}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
interface IValueResultStringComponentProps {
    computationOutputValue: IMonitoringResultValueStringModel
}
function ValueResultStringComponent({ computationOutputValue: { label, value, direction, sentiment, validity } }: IValueResultStringComponentProps) {
    return <SummaryField
        label={label}
        value={value}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
interface IValueResultMarkdownComponentProps {
    computationOutputValue: IMonitoringResultValueMarkdownModel
}
function ValueResultMarkdownComponent({ computationOutputValue: { value } }: IValueResultMarkdownComponentProps) {
    return <MarkDown markDown={value} />
}
interface IValueResultReferenceComponentProps {
    computationOutputValue: IMonitoringResultValueReferenceModel
}
interface IValueResultDueDiligenceFileComponentProps {
    computationOutputValue: IMonitoringResultValueDueDiligenceFileModel;
}
interface IValueResultFileComponentProps {
    computationOutputValue: IMonitoringResultValueFileModel;
}
interface IButtonLinkProps {
    id: number;
    textValue: string;
    referenceType: IReferenceTypeModel;
}
// function getScreenKey(referenceType: IReferenceTypeModel): IUrlKey | undefined {
//     switch (referenceType) {
//         // case IReferenceTypeModel.Person: return "People";
//         // case IReferenceTypeModel.Company: return "Companies";
//         // case IReferenceTypeModel.EntityGroup: return "EntityGroups";
//         // case IReferenceTypeModel.ManagedSicav: return "MySicavs";
//         // case IReferenceTypeModel.Portfolio: return "MyPortfolios";
//         // case IReferenceTypeModel.ShareClass: return "MyShareClasses";
//         // case IReferenceTypeModel.Sicav: return "Sicavs";
//         // case IReferenceTypeModel.SubFund: return "SubFunds";
//         // case IReferenceTypeModel.Security: return "Securities";
//         case IReferenceTypeModel.Investor: return "Investors";
//         // case IReferenceTypeModel.Role: return "ServiceProvidersCollaborators";
//         // case IReferenceTypeModel.Counterparty: return "Counterparties";
//         // case IReferenceTypeModel.Index: return "Indexes";
//         // case IReferenceTypeModel.Movement: return "CashMovements";
//         default: return;
//     }
// }
function ButtonLink({ id, textValue, referenceType }: IButtonLinkProps) {
    // const { navigationNavigate } = useReduxActions("navigation");
    const handleClick = () => {
        // navigationNavigate({ screenKey: getScreenKey(referenceType), sectionKey: "detail", parameters: { id } })
    };
    // variant="body2"
    return <Link
        component="button"
        onClick={handleClick}>
        {textValue}
    </Link>;
}
interface IButtonDueDiligenceLinkProps {
    id: number;
    textValue: string;
    dueDiligenceType: IDueDiligenceTypeModel;
    taskCode: string;
}
function ButtonDueDiligenceFileLink({ id, textValue, dueDiligenceType, taskCode }: IButtonDueDiligenceLinkProps) {
    // const { relationshipLoadFile } = useReduxActions("relationship");






    const handleClick = () => {
        switch (dueDiligenceType) {
            // case IDueDiligenceTypeModel.Investor:
            //     relationshipLoadFile({ id, type: "InvestorRelationshipModel", taskCode });
            //     break;
            // case IDueDiligenceTypeModel.Collaborator:
            //     relationshipLoadFile({ id, type: "RoleRelationshipModel", taskCode });
            //     break;
            // case IDueDiligenceTypeModel.Counterparty:
            //     relationshipLoadFile({ id, type: "CounterpartyRelationshipModel", taskCode });
            //     break;
            // case IDueDiligenceTypeModel.EntityCheck:
            //     entityLoadChecksFile({ id, taskCode });
            //     break;
            // case IDueDiligenceTypeModel.EntityCompliance:
            //     entityLoadedComplianceFile({ id, taskCode });
            //     break;
            // case IDueDiligenceTypeModel.SecurityCheck:
            //     securityLoadChecksFile({ id, taskCode });
            //     break;
            // case IDueDiligenceTypeModel.SecurityCompliance:
            //     securityLoadComplianceFile({ id, taskCode });
            //     break;
            // case IDueDiligenceTypeModel.SicavCheck:
            //     sicavLoadChecksFile({ id, taskCode });
            //     break;
            // case IDueDiligenceTypeModel.SicavCompliance:
            //     sicavLoadedComplianceFile({ id, taskCode });
            //     break;
        }
    };

    // const handleClick = () => navigationNavigate({ screenKey: getScreenKey(referenceType), sectionKey: "detail", parameters: { id } });
    // variant="body2"
    return <Link
        component="button"
        onClick={handleClick}>
        {textValue}
    </Link>;
}
interface IButtonFileLinkProps {
    id: number;
    textValue: string;
    sourceType: IFileSourceTypeModel;
}
function ButtonFileLink({ id, textValue, sourceType }: IButtonFileLinkProps) {
    // const { cashMovementLoadFile } = useReduxActions("cashMovement");
    const { entityFileContentLoad } = useReduxActions("entity");

    const handleClick = () => {
        switch (sourceType) {
            // case IFileSourceTypeModel.CashMovement:
            //     cashMovementLoadFile(id);
            //     break;
            case IFileSourceTypeModel.EntityDocument:
                entityFileContentLoad({ fileId: id });
                break;
        }
    };

    // const handleClick = () => navigationNavigate({ screenKey: getScreenKey(referenceType), sectionKey: "detail", parameters: { id } });
    // variant="body2"
    return <Link
        component="button"
        onClick={handleClick}>
        {textValue}
    </Link>;
}
function ValueResultReferenceComponent({ computationOutputValue: { label, value, textValue, referenceType, direction, sentiment, validity } }: IValueResultReferenceComponentProps) {
    return <SummaryField
        label={label}
        value={<ButtonLink id={value} textValue={textValue} referenceType={referenceType} />}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
function ValueResultDueDiligenceFileComponent({ computationOutputValue: { label, value, textValue, dueDiligenceType, direction, sentiment, validity, taskCode } }: IValueResultDueDiligenceFileComponentProps) {
    return <SummaryField
        label={label}
        value={<ButtonDueDiligenceFileLink id={value} textValue={textValue} dueDiligenceType={dueDiligenceType} taskCode={taskCode} />}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
function ValueResultFileComponent({ computationOutputValue: { label, value, textValue, sourceType, direction, sentiment, validity } }: IValueResultFileComponentProps) {
    return <SummaryField
        label={label}
        value={<ButtonFileLink id={value} textValue={textValue} sourceType={sourceType} />}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
        endAdornment={(typeof (validity) !== "undefined") && <ComputationValidityComponent validity={validity} />}
    />
}
interface IDirectionComponentProps {
    direction?: IProgressionLevelModel
    sentiment?: ISentimentModel;
}
function getSentimentColor(sentiment: ISentimentModel | undefined): DataType.Color | undefined {
    switch (sentiment) {
        case ISentimentModel.Negative: return "red";
        case ISentimentModel.Neutral: return "orange";
        case ISentimentModel.Positive: return "green";
    }
    return;
}
function DirectionComponent({ direction, sentiment }: IDirectionComponentProps) {
    if (typeof (direction) === "undefined") {
        return null;
    }

    const sentimentColor = getSentimentColor(sentiment);

    switch (direction) {
        case IProgressionLevelModel.LargeRaise: return <ArrowUpBoldIcon style={{ color: sentimentColor }} />;
        case IProgressionLevelModel.Raise: return <TrendingUpIcon style={{ color: sentimentColor }} />;
        case IProgressionLevelModel.Still: return <TrendingFlatIcon style={{ color: sentimentColor }} />;
        case IProgressionLevelModel.Drop: return <TrendingDownIcon style={{ color: sentimentColor }} />;
        case IProgressionLevelModel.LargeDrop: return <ArrowDownBoldIcon style={{ color: sentimentColor }} />;
    }
}
type AvatarVariant = 'circle' | 'rounded' | 'square';
interface IComputationTitleProps extends IComputationValidityAvatarProps {
    title: string;
    titleVariant?: Variant;
    expanded?: boolean;
    onExpandChanged?: (expanded: boolean) => void;
}
function ComputationTitle({ title, validity, variant = "circle", titleVariant = "h6", size, expanded, onExpandChanged }: IComputationTitleProps) {
    return <Typography gutterBottom={true} variant={titleVariant} component="h2">
        <Box display="flex" alignItems={"center"} gridGap={16}>
            {(typeof (expanded) !== "undefined" && !!onExpandChanged) && <ExpandCollapseButton expanded={expanded} onChanged={onExpandChanged} />}
            <ComputationValidityAvatar variant={variant} validity={validity} size={size} />
            <span>{title}</span> 
        </Box>
    </Typography>;
}
type Size = "small" | "normal"
interface IComputationValidityAvatarProps {
    validity: IMonitoringResultValidityModel | undefined | null;
    variant?: AvatarVariant;
    size: Size;
}
function ComputationValidityAvatar({ validity, variant = "circle", size }: IComputationValidityAvatarProps) {
    const classes = useStyles();
    return (typeof (validity) !== "undefined" && validity !== null) ? <Avatar className={size === "small" ? classes.smallAvatar : classes.regularAvatar} variant={variant}><ComputationValidityComponent validity={validity} size={size} /></Avatar> : null;
}
export interface IComputationValidityComponentProps {
    validity: IMonitoringResultValidityModel | undefined | null;
    size?: Size;
}
export function ComputationValidityComponent({ validity, size = "normal" }: IComputationValidityComponentProps) {
    if (typeof (validity) === "undefined" || validity === null) {
        return null;
    }
    const fontSize = size === "small" ? "small" : undefined;
    switch (validity) {
        case IMonitoringResultValidityModel.Invalid: return <ThumbDownIcon style={{ color: "red" }} fontSize={fontSize} />;
        case IMonitoringResultValidityModel.Valid: return <ThumbUpIcon style={{ color: "green" }} fontSize={fontSize} />;
        case IMonitoringResultValidityModel.Warning: return <ReportProblemIcon style={{ color: "yellow" }} fontSize={fontSize} />;
    }
}
