import { useReduxActions, useReduxSelections } from "lib/reduxStoreAccess"
import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { InnerPortalScreen } from "../AbstractPortal/InnerPortalScreen";
import { IUniverseScopeTypeModel } from "proxy/apiProxy";
// import { IUniverseScopeTypeModel } from "proxy/apiProxy";
export default function Main() {
    const { entityOpen, entityReportGenerate, entityDocumentGenerate,
        entityProcessFileSave,
        entityProcessFileDelete,
        entityProcessFileLoad,
        entityProcessExecutionLoadQuestionnaire,
        entityProcessExecutionSaveQuestionnaire
    } = useReduxActions("entity");
    const { processClassificationTypes = [] } = useReduxSelections("app");
    useEffect(() => void entityOpen(), [entityOpen]);
    const state = useReduxSelections("entity");

    return <Grid container spacing={3} style={{ overflow: "clip", paddingBottom: 32, paddingTop: 32 }}>
        <InnerPortalScreen
            documentGenerate={entityDocumentGenerate}
            reportGenerate={entityReportGenerate}
            processClassificationTypes={processClassificationTypes}

            onProcessFileSave={entityProcessFileSave}
            onProcessFileDelete={entityProcessFileDelete}
            onProcessFileLoad={entityProcessFileLoad}
            onProcessLoadQuestionnaire={entityProcessExecutionLoadQuestionnaire}
            onProcessSaveQuestionnaire={entityProcessExecutionSaveQuestionnaire}

            scopeType={IUniverseScopeTypeModel.Entity}
            state={state} />
    </Grid>
}
