import produce from "immer";
import {
    IGetRelationshipModel,
    IMonitoringResultsetModel,
    IMonitoringMacroScriptSummaryModel,
    RelationshipModel,
    IRelationshipsGetMonitoringResultForTargetParameters,
    IRelationshipsGenerateReportParameters,
    IRelationshipsGenerateDocumentParameters,
    IGetProcessExecutionsPortalModel,
    IProcessExecutionSaveProcessFileParameters,
    IProcessExecutionDeleteProcessFileParameters,
    IProcessExecutionGetProcessFileParameters,
    IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters,
    IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "lib/store";
import { IPortalScreenState, IProcessExecutionLoadedQuestionnairePayload, IProcessExecutionSavedQuestionnairePayload, IProcessFileSavedPayload } from "features/AbstractPortal/IPortalScreenState";

export const ActionFactories = produceActionFactories({
    relationshipOpen: (payload: number) => payload,

    relationshipLoad: (payload: number) => payload,
    relationshipLoaded: (payload: IGetRelationshipModel) => payload,

    relationshipMonitoringLoad: (payload: Omit<IRelationshipsGetMonitoringResultForTargetParameters, "monitoringId"> & IMonitoringMacroScriptSummaryModel) => payload,
    relationshipMonitoringLoaded: (payload: IMonitoringResultsetModel) => payload,

    relationshipReportGenerate: (payload: IRelationshipsGenerateReportParameters) => payload,
    relationshipReportGenerated: (payload: number) => payload,

    relationshipDocumentGenerate: (payload: IRelationshipsGenerateDocumentParameters) => payload,
    relationshipDocumentGenerated: (payload: number) => payload,

    relationshipProcessesLoad: (payload: number) => undefined,
    relationshipProcessesLoaded: (payload: IGetProcessExecutionsPortalModel) => payload,

    relationshipProcessFileSave: (payload: IProcessExecutionSaveProcessFileParameters) => payload,
    relationshipProcessFileSaved: (payload: IProcessFileSavedPayload) => payload,

    relationshipProcessFileDelete: (payload: IProcessExecutionDeleteProcessFileParameters) => payload,
    relationshipProcessFileDeleted: (payload: IProcessFileSavedPayload) => payload,

    relationshipProcessFileLoad: (payload: IProcessExecutionGetProcessFileParameters) => payload,
    relationshipProcessFileLoaded: (payload: IProcessExecutionGetProcessFileParameters) => payload,

    relationshipProcessExecutionLoadQuestionnaire: (payload: IProcessExecutionGetProcessExistingExecutionQuestionnaireParameters) => payload,
    relationshipProcessExecutionLoadedQuestionnaire: (payload: IProcessExecutionLoadedQuestionnairePayload) => payload,

    relationshipProcessExecutionSaveQuestionnaire: (payload: IProcessExecutionSaveProcessExecutionQuestionnaireResponseParameters) => payload,
    relationshipProcessExecutionSavedQuestionnaire: (payload: IProcessExecutionSavedQuestionnairePayload) => payload,
});

export interface IState extends IPortalScreenState {
    loading: boolean;
    relationship?: RelationshipModel;
}
const initialState: IState = {
    loading: false,
    dictionaries: {
        entities: {},
        relationships: {},
        portfolios: {},
        securities: {}
    },
    relationship: undefined,
    documentIssuing: {},
    reportIssuing: {},
    monitorings: {},
    processesTaskState: {},
    processExecutions: [],
    processExecutionsLoading: false
};
export type Action = AnyActionOf<typeof ActionFactories>;
export const reducer = (
    state: IState = initialState,
    action: Action
) => produce(state, draft => {
    const getTaskState = (processId: number, taskCode: string) => {
        draft.processesTaskState[processId] ??= {};
        draft.processesTaskState[processId][taskCode] ??= {};
        return draft.processesTaskState[processId][taskCode];
    }
    switch (action.type) {
        case "relationshipOpen":
            Object.assign(draft, initialState);
            break;
        case "relationshipProcessesLoad":
            draft.processExecutionsLoading = true;
            break;
        case "relationshipProcessesLoaded":
            draft.processExecutionsLoading = false;
            draft.processExecutions = action.payload.processExecutions;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            break;
        case "relationshipProcessExecutionLoadQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "relationshipProcessExecutionLoadedQuestionnaire":
            const taskState = getTaskState(action.payload.processId, action.payload.taskCode);
            taskState.loading = false;
            taskState.questionnaireDetails = action.payload.taskState;
            break;
        case "relationshipProcessExecutionSaveQuestionnaire":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "relationshipProcessExecutionSavedQuestionnaire":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "relationshipProcessFileLoad":
            getTaskState(action.payload.id, action.payload.taskCode).loading = true;
            break;
        case "relationshipProcessFileLoaded":
            getTaskState(action.payload.id, action.payload.taskCode).loading = false;
            break;
        case "relationshipProcessFileSave":
            getTaskState(action.payload.id, action.payload.taskCode).saving = true;
            break;
        case "relationshipProcessFileSaved":
            {
                getTaskState(action.payload.processId, action.payload.task.code).saving = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "relationshipProcessFileDelete":
            getTaskState(action.payload.id, action.payload.taskCode).deleting = true;
            break;
        case "relationshipProcessFileDeleted":
            {
                getTaskState(action.payload.processId, action.payload.task.code).deleting = false;
                const processExecution = draft.processExecutions.find(i => i.id === action.payload.processId);
                if (processExecution) {
                    const taskIndex = processExecution.tasks.findIndex(i => i.code === action.payload.task.code);
                    if (taskIndex >= 0) {
                        processExecution.tasks[taskIndex] = action.payload.task;
                    }
                }
            }
            break;
        case "relationshipMonitoringLoad":
            draft.monitorings[action.payload.id] = { loading: true, ...action.payload };
            break;
        case "relationshipMonitoringLoaded":
            draft.monitorings[action.payload.monitoringMacroId] = { loading: false, ...action.payload };
            break;
        case "relationshipLoad":
            draft.loading = true;
            draft.dictionaries = {
                entities: {},
                relationships: {},
                portfolios: {},
                securities: {}
            };
            break;
        case "relationshipReportGenerate":
            draft.reportIssuing[action.payload.reportTemplateId] = true;
            break;
        case "relationshipReportGenerated":
            draft.reportIssuing[action.payload] = false;
            break;
        case "relationshipDocumentGenerate":
            draft.documentIssuing[action.payload.documentDefinitionId] = true;
            break;
        case "relationshipDocumentGenerated":
            draft.documentIssuing[action.payload] = false;
            break;




        case "relationshipLoaded":
            draft.relationship = action.payload.relationship;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            break;
    }
});

